<h3>Terms & Conditions</h3>
<p>
    CIO TouchCast Collection è la raccolta dei video creati da CIO Organization, consultabile da tutto il personale.
    Completa la procedura cliccando su “Agree”. Puoi annullare l’approvazione adesso cliccando su “Not Agree” o in
    qualsiasi altro momento scrivendo a <u>Touchcast.CIO.Italy@accenture.com</u>.
</p>

<div class="buttons">
<button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
    <mat-icon>check</mat-icon> Agree
    </button>

<button mat-button color="primary" [mat-dialog-close]="false">
    <mat-icon>clear</mat-icon> Not Agree
</button>
</div>