import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../../core/services/data-base.service';
import { DataService } from '../../core/services/data.service';
import { Router } from '@angular/router';
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'rebar-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  numbers = [
    'Loading..',
    'Loading..',
    'Loading..',
    'Loading..',
    'Loading..',
    'Loading..',
    'Loading..',
    'Loading..',
    'Loading..',
    'Loading..',
  ];

  constructor(
    private dataBaseService: DataBaseService,
    private dataService: DataService,
    private router: Router,
    private _snackBar: MatSnackBar,
    public userService: UserService,
    private auth: RebarAuthService
  ) {}

  ngOnInit() {
    this.dataBaseService.getHomeData().subscribe((arg) => {
      console.log(arg.body);

      for (let i = 1; i <= this.numbers.length; i++) {
        arg.body[i]
          ? (this.numbers[i - 1] = arg.body[i].count)
          : (this.numbers[i - 1] = '0');
      }
    });
  }

  navigateToRequests() {
    this.dataService.allRequests = false;
    this.router.navigateByUrl('RequestView');
  }

  navigateToRequestsFilter(statusFilter) {
    this.dataService.allRequests = true;
    this.dataService.requestStatusFilter = statusFilter;
    this.router.navigateByUrl('RequestView');
  }

  getPdf(fileId: number) {
    this.dataBaseService.getPdf(fileId).subscribe(
      (response) => {
        console.log(response);
        const fileName = response.headers
          .get('Content-Disposition')
          .split(';')[1]
          .trim()
          .split('=')[1]
          .replace(/"/g, '');
        console.log(fileName);
        const element = document.createElement('a');
        element.href = URL.createObjectURL(response.body);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        URL.revokeObjectURL(element.href);
      },
      (err) => {
        console.log(err);
        this._snackBar.open('Error ' + err.status, 'File not found', {
          duration: 5000,
        });
      }
    );
  }
}
