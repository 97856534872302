import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { DataBaseService } from '../../core/services/data-base.service';
import { DataService } from '../../core/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import {
  MatTableModule,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { UserService } from '../../core/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeletePopupComponent } from '../../shared/delete-popup/delete-popup.component';

import * as Model from '../../core/Models/Models';

@Component({
  selector: 'rebar-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.css'],
})
export class RequestViewComponent implements OnInit, OnDestroy {
  constructor(
    private dataBaseService: DataBaseService,
    private dataService: DataService,
    private router: Router,
    public userService: UserService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  tableHeader: Array<string> = [
    'id',
    'requestor',
    'title',
    'created',
    'requestTypeDescription',
    'requestSubTypeDescription',
    'expectedDate',
    'effectiveDate',
    'statusDescription',
    'mediaUrl',
    'View',
  ];
  dataSource = new MatTableDataSource<Model.RequestDtoOut>();
  spinner = true;

  idFilter = '';
  allRequests = this.dataService.allRequests;
  requestorFilter = '';
  titleFilter = '';
  createdFilter = '';
  requestTypeDescriptionFilter = '';
  requestSubTypeDescriptionFilter = '';
  expectedDateFilter = '';
  effectiveDateFilter = '';
  statusDescriptionFilter = this.dataService.requestStatusFilter;
  mediaUrlFilter = '';

  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.refreshTable();
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return (
        (this.idFilter.length == 0 ||
          data.id.toString().includes(this.idFilter)) &&
        (this.requestorFilter.length == 0 ||
          data.requestor.toLowerCase().includes(this.requestorFilter)) &&
        (this.titleFilter.length == 0 ||
          (data.title + '').toLowerCase().includes(this.titleFilter)) &&
        (this.createdFilter.length == 0 ||
          data.created.toString().includes(this.createdFilter)) &&
        (this.requestTypeDescriptionFilter.length == 0 ||
          (data.requestTypeDescription + '')
            .toLowerCase()
            .includes(this.requestTypeDescriptionFilter)) &&
        (this.requestSubTypeDescriptionFilter.length == 0 ||
          (data.requestSubTypeDescription + '')
            .toLowerCase()
            .includes(this.requestSubTypeDescriptionFilter)) &&
        (this.expectedDateFilter.length == 0 ||
          (data.expectedDate + '')
            .toLowerCase()
            .includes(this.expectedDateFilter)) &&
        (this.effectiveDateFilter.length == 0 ||
          (data.effectiveDate + '')
            .toLowerCase()
            .includes(this.effectiveDateFilter)) &&
        (this.statusDescriptionFilter.length == 0 ||
          ('\\' + data.statusDescription)
            .toLowerCase()
            .includes(this.statusDescriptionFilter)) &&
        (this.mediaUrlFilter.length == 0 ||
          (data.mediaUrl + '').toLowerCase().includes(this.mediaUrlFilter))
      );
    };
    this.applyFilter();
  }

  applyFilter() {
    this.dataSource.filter = (
      this.titleFilter +
      this.createdFilter +
      this.idFilter +
      this.requestorFilter +
      this.requestSubTypeDescriptionFilter +
      this.requestTypeDescriptionFilter +
      this.expectedDateFilter +
      this.effectiveDateFilter +
      this.statusDescriptionFilter +
      this.mediaUrlFilter
    )
      .trim()
      .toLowerCase();
  }
  refreshTable() {
    this.spinner = true;
    this.dataBaseService.getRequests(this.allRequests).subscribe((arg) => {
      console.log(arg);
      this.dataSource.data = arg.body;
      this.spinner = false;
    });
  }
  clearFilter() {
    this.idFilter = '';
    this.requestorFilter = '';
    this.titleFilter = '';
    this.createdFilter = '';
    this.requestTypeDescriptionFilter = '';
    this.requestSubTypeDescriptionFilter = '';
    this.expectedDateFilter = '';
    this.effectiveDateFilter = '';
    this.statusDescriptionFilter = '';
    this.mediaUrlFilter = '';
    this.applyFilter();
  }

  ngOnDestroy() {
    this.dataService.requestStatusFilter = '';
    this.dataService.allRequests = false;
  }

  openForm(element, isView: boolean) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        isView,
        id: element.id,
      },
    };
    this.router.navigate(['../FormEditView'], navigationExtras);
  }

  delete(request: Model.RequestDtoOut) {
    const dialogRef = this.dialog.open(DeletePopupComponent, {
      width: '550px',
      data: { id: request.id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      this.refreshTable();
    });
  }
}
