<div class="background"></div>

<div class="grid-container">
    <div class="createButton box" routerLink="../Form">
        <h3>
            <mat-icon class="menuIcon">add_circle</mat-icon>CREATE NEW REQUEST
        </h3>
        <br>
    </div>
    <div class="viewButton box" (click)="navigateToRequests()">
        <h3>
            <mat-icon class="menuIcon">visibility</mat-icon>VIEW MY REQUESTS
        </h3>
        <br>
    </div>

    <ng-container *ngIf="userService.isAdministrator || userService.isTCTeam || userService.isDeveloper">
        <div class="box" (click)="navigateToRequestsFilter('draft')">
            <h3>
                <mat-icon class="menuIcon">save</mat-icon>{{numbers[0]}}
            </h3>
            <br>
            <h4>Draft</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('\\submitted')">
            <h3>
                <mat-icon class="menuIcon">insert_drive_file</mat-icon>{{numbers[1]}}
            </h3>
            <br>
            <h4>Submited</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('approved')">
            <h3>
                <mat-icon class="menuIcon">check</mat-icon>{{numbers[2]}}
            </h3>
            <br>
            <h4>Approved</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('rejected by tc team')">
            <h3>
                <mat-icon class="menuIcon">block</mat-icon>{{numbers[3]}}
            </h3>
            <br>
            <h4>Rejected by TC team</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('missing data info')">
            <h3>
                <mat-icon class="menuIcon">info</mat-icon>{{numbers[4]}}
            </h3>
            <br>
            <h4>Missing Data Info</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('answered')">
            <h3>
                <mat-icon class="menuIcon">check_box</mat-icon>{{numbers[5]}}
            </h3>
            <br>
            <h4>Answered</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('resubmitted')">
            <h3>
                <mat-icon class="menuIcon">reply_all</mat-icon>{{numbers[6]}}
            </h3>
            <br>
            <h4>Resubmitted</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('completed')">
            <h3>
                <mat-icon class="menuIcon">check_circle_outline</mat-icon>{{numbers[7]}}
            </h3>
            <br>
            <h4>Completed</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('closed')">
            <h3>
                <mat-icon class="menuIcon">cancel</mat-icon>{{numbers[8]}}
            </h3>
            <br>
            <h4>Closed</h4>
        </div>
        <div class="box" (click)="navigateToRequestsFilter('deleted')">
            <h3>
                <mat-icon class="menuIcon">delete</mat-icon>{{numbers[9]}}
            </h3>
            <br>
            <h4>Deleted</h4>
        </div>
    </ng-container>
    <div class="box">
        <ul class="linkList">
            <li><a href="https://goo.gl/rL4xJ2" target="_blank" rel="noopener">THE SERVICE</a></li>
            <li><a href="https://goo.gl/1P7Tr6" target="_blank" rel="noopener">OUR COLLECTION</a></li>
            <li><a class="link" (click)="getPdf(2)">USER GUIDE & FAQ</a></li>
            <li><a href="mailto:TouchCast.CIO.Italy@accenture.com" target="_blank" rel="noopener">CONTACTS</a></li>
        </ul>
    </div>


</div>