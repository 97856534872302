import { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

export interface DialogData {
  flag: boolean;
}

@Component({
  selector: 'rebar-collection-flag-popup',
  templateUrl: './collection-flag-popup.component.html',
  styleUrls: ['./collection-flag-popup.component.css'],
})
export class CollectionFlagPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CollectionFlagPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {}
}
