  <!-- Nav bar based on affinity design guidline -->
  <nav class="navbar navbar-toggleable-sm bg-faded">
    <button class="navbar-toggler navbar-toggler-right m-0 r-0" type="button" data-toggle="collapse" data-target="#navbarNavPrimary"
      aria-controls="navbarNavPrimary" aria-expanded="false" aria-label="Toggle navigation">
      <elicon type="md-icon" name="menu"></elicon>
    </button>
    <a class="navbar-brand" href="#">
     REBAR SPA template - Angular 8
    </a>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
      </ul>
    </div>
    <ul class="nav navbar-tabs text-center float-right hidden-sm-down">
      <li class="nav-item">
        <a class="nav-link">
          <elicon type="md-icon" name="search"></elicon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <elicon type="md-icon" name="apps"></elicon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <elicon type="md-icon" name="notifications"></elicon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <elicon type="md-icon" name="help"></elicon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <elicon type="md-icon" name="settings"></elicon>
        </a>
      </li>
      
    </ul>
  </nav>
  <div class="navbar-toggleable-sm">
    <div class="collapse navbar-collapse" id="navbarNavPrimary">
      <nav class="nav nav-tabs primary-tabs d-block d-md-flex w-100">
        <li class="nav-item">
          <a id="home" class="nav-link" routerLink="/home" (click)='updateClass($event)'>Home</a>
        </li>
        <li class="nav-item">
          <a id="dash" class="nav-link" routerLink="/dashboard" (click)='updateClass($event)'>Dashboard</a>
        </li>
        <li class="nav-item">
          <a id="admin" class="nav-link" routerLink="/admin" (click)='updateClass($event)'>Admin</a>
        </li>
      </nav>
    </div>
  
  </div>