import { Injectable } from '@angular/core';
import { UserDtoOut } from '../Models/Models';
import { DataBaseService } from './data-base.service';
//import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { Observable } from 'rxjs';
import { RebarAuthService } from '../rebarauth/rebar.auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private dataBaseService: DataBaseService,
    private auth: RebarAuthService
  ) {}

  User: UserDtoOut = {
    id: 0,
    account: this.auth.getUser() ? this.auth.getUser() : '',
    isDomainGroup: false,
    roles: null,
    name: this.auth.getUsername() ? this.auth.getUsername() : '',
    enterpriseId : this.auth.getUser()?.substring(0, this.auth.getUser()?.indexOf('@'))
  };
  isDeveloper = false;
  isAdministrator = false;
  isTCTeam = false;
}
