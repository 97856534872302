import { Component, OnInit } from '@angular/core';
import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { datadogRum } from '@datadog/browser-rum';
import { AppConfigService } from './core/services/app-config.service';
import { DatadogSessionService } from './core/services/datadog-session.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataBaseService } from './core/services/data-base.service';
import { UserService } from './core/services/user.service';
import { DataService } from './core/services/data.service';
import { AboutPopUpComponent } from './feature/about-pop-up/about-pop-up.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'touchcast-portal';
  isVisible = false;
  isLoading = true;
  panelOpenState = false;
  eid = '';
  username ='';

  constructor(
    public rebarAuthService: RebarAuthService,
    private appConfigService: AppConfigService,
    private datadogSessionService: DatadogSessionService,
    public dialog: MatDialog,
    private dataBaseService: DataBaseService,
    public userService: UserService,
    private dataService: DataService,
    private router: Router,
    private httpClient: HttpClient
  ) {}

  user = this.rebarAuthService.getUser();
  year = new Date().getFullYear();

  ngOnInit(): void {
    this.rebarAuthService.authObserver$.subscribe((authStatus) => {
      console.log(`user is logged in ${authStatus}`);
      if(authStatus){
        this.afterAuth();
      }
    });

    datadogRum.init({
      applicationId: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['appId'],
      clientToken: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['clientToken'],
      site: 'datadoghq.com',
      service: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['service'],
      env: (this.appConfigService.config['datadog'] as Record<string, string>)[
        'environment'
      ],
      sampleRate: 100,
      trackInteractions: true,
    });
    datadogRum.setUser({
      id: this.datadogSessionService.setDataDogSessionId(),
    });

    this.afterAuth();
  }

  afterAuth(): void {
    // show app content only if user is authenticated or app is running in
    // mock eso configuration using npm run start:local command

    if (!this.rebarAuthService.authenticationEnabled()) this.isVisible = true;
    if (
      this.rebarAuthService.authenticationEnabled() &&
      this.rebarAuthService.IsUserAuthenticated()
    ) {
      this.isVisible = true;
      this.username = this.rebarAuthService.getUsername();

      this.dataBaseService.LogIn().subscribe((user) => {
        if (user) {
          this.userService.isDeveloper =
            user.roles.filter((role) => role.description == 'Developer')
              .length != 0;
          this.userService.isAdministrator =
            user.roles.filter((role) => role.description == 'Administrator')
              .length != 0;
          this.userService.isTCTeam =
            user.roles.filter((role) => role.description == 'TCTeam').length !=
            0;
          // this.userService.isDeveloper = this.isDeveloper;
          // this.userService.isAdministrator = this.isAdministrator;
          // this.userService.isTCTeam = this.isTCTeam;

          this.userService.User = user;                  
        }
        console.log(user);
        this.isLoading = false;
      });
      this.dataBaseService.GetClaims().subscribe((user) => {
        console.log(user);
      });
    }
  }

  openAbout(): void {
    const dialogRef = this.dialog.open(AboutPopUpComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  navigateToRequests() {
    this.dataService.allRequests = true;
    // go to home and then to the request viewpage, so that the button works when you already are in the myrequests page
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['RequestView']));
  }

}
