<br>
<div class="center">
    <div class="tableTitle">
        <h2 *ngIf="allRequests">AllRequests Touchcast – My Requests</h2>
        <h2 *ngIf="!allRequests">My Touchcast – My Requests</h2>

        <span class="fill"></span>
        <button mat-button color="primary" *ngIf="spinner">
            <div class="spinner-button">
                <mat-spinner style="margin:5px;" [diameter]="25"></mat-spinner><span> Refresh</span>
            </div>
        </button>
        <button mat-button color="primary" (click)="refreshTable()" *ngIf="!spinner">
            <mat-icon class="refreshIcon">refresh</mat-icon> Refresh
        </button>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="id">
                <th class="idColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="requestor">
                <th class="requestorColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Requestor </th>
                <td mat-cell *matCellDef="let element"> {{element.requestor}} </td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th class="titleColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Request Title </th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th class="createdColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Request Date </th>
                <td mat-cell *matCellDef="let element"> {{element.created}} </td>
            </ng-container>
            <ng-container matColumnDef="requestTypeDescription">
                <th class="requestTypeDescriptionColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Request Type
                    Description </th>
                <td mat-cell *matCellDef="let element"> {{element.requestTypeDescription}} </td>
            </ng-container>
            <ng-container matColumnDef="requestSubTypeDescription">
                <th class="requestSubTypeDescriptionColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Request
                    Type Details </th>
                <td mat-cell *matCellDef="let element"> {{element.requestSubTypeDescription}} </td>
            </ng-container>
            <ng-container matColumnDef="expectedDate">
                <th class="expectedDateColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Expected Date </th>
                <td mat-cell *matCellDef="let element"> {{element.expectedDate}} </td>
            </ng-container>
            <ng-container matColumnDef="effectiveDate">
                <th class="effectiveDateColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Effective Date </th>
                <td mat-cell *matCellDef="let element"> {{element.effectiveDate}} </td>
            </ng-container>
            <ng-container matColumnDef="statusDescription">
                <th class="statusDescriptionColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element">
                    <div [ngSwitch]="element.statusDescription">
                        <span *ngSwitchCase="'Draft'" class="badge"
                            style="background-color: #1F4776 ; border: #1F4776 ;color: snow;">
                            <mat-icon>save</mat-icon>
                            Draft
                        </span>
                        <span *ngSwitchCase="'Submitted'" class="badge"
                            style="background-color: #337ab7; border: #337ab7;color: snow;">
                            <mat-icon>description</mat-icon>
                            Submitted
                        </span>
                        <span *ngSwitchCase="'Approved'" class="badge"
                            style="background-color: #337ab7; border: #337ab7;color: snow;">
                            <mat-icon>description</mat-icon>
                            Approved
                        </span>
                        <span *ngSwitchCase="'Rejected by TC Team'" class="badge"
                            style="background-color: #800909; border: #800909;color: snow;">
                            <mat-icon>not_interested</mat-icon>
                            Rejected by TC Team
                        </span>
                        <span *ngSwitchCase="'Missing Data Info'" class="badge"
                            style="background-color: #F4D03F; border: #F4D03F;">
                            <mat-icon>info</mat-icon>
                            Missing Data Info
                        </span>
                        <span *ngSwitchCase="'Answered'" class="badge"
                            style="background-color: #4CA14A; border: #4CA14A;color: snow;">
                            <mat-icon>check_box</mat-icon>
                            Answered
                        </span>
                        <span *ngSwitchCase="'Resubmitted'" class="badge"
                            style="background-color: #337ab7; border: #337ab7;color: snow;">
                            <mat-icon>description</mat-icon>
                            Resubmitted
                        </span>
                        <span *ngSwitchCase="'Completed'" class="badge"
                            style="background-color: #327031; border: #327031;color: snow;">
                            <mat-icon>check</mat-icon>
                            Completed
                        </span>

                        <span *ngSwitchCase="'Closed'" class="badge"
                            style="background-color: #315782; border: #315782;color: snow;">
                            <mat-icon>cancel</mat-icon>
                            Closed
                        </span>
                        <span *ngSwitchCase="'Deleted'" class="badge"
                            style="background-color: #800909; border: #800909;color: snow;">
                            <mat-icon>delete</mat-icon>
                            Deleted
                        </span>
                        <span *ngSwitchDefault class="badge"
                            style="background-color: #337ab7; border: #337ab7;color: snow;">
                            <mat-icon>icon</mat-icon>
                            {{element.statusDescription}}
                        </span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="mediaUrl">
                <th class="mediaUrlColumn" mat-header-cell *matHeaderCellDef mat-sort-header> Media Link </th>
                <td mat-cell *matCellDef="let element"> {{element.mediaUrl}} </td>
            </ng-container>

            <ng-container matColumnDef="View">
                <th class="buttonColumn" mat-header-cell *matHeaderCellDef> Actions </th>
                <td class="buttonColumn text-nowrap" mat-cell *matCellDef="let element">

                    <ng-container
                        *ngIf="userService.isAdministrator || userService.isTCTeam || userService.isDeveloper">
                        <button (click)="openForm(element, true)" class="tableBtn"
                            style="background-color: #337ab7; border: #337ab7;color: snow;"
                            *ngIf="element.statusDescription === 'Closed' || element.statusDescription === 'Rejected by TC Team' || element.statusDescription === 'Completed' || element.statusDescription === 'Draft' || element.statusDescription === 'Deleted'">
                            <mat-icon>description</mat-icon>
                        </button>
                        <ng-container *ngIf="element.statusDescription !== 'Deleted'">
                            <button (click)="openForm(element, false)" class="tableBtn"
                                style="background-color: #337ab7; border: #337ab7;color: snow;"
                                *ngIf="(element.statusDescription !== 'Closed' && element.statusDescription !== 'Rejected by TC Team' && element.statusDescription !== 'Completed' && element.statusDescription !== 'Draft') || (element.statusDescription === 'Draft' && userService.User.enterpriseId == element.requestor)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button (click)="delete(element)" class="tableBtn"
                                style="background-color: #800909; border: #800909;color: snow;"
                                *ngIf="element.statusDescription !== 'Draft' || userService.User.enterpriseId == element.requestor">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container>

                    </ng-container>
                    <ng-container
                        *ngIf="!(userService.isAdministrator || userService.isTCTeam || userService.isDeveloper)">

                        <button (click)="openForm(element, true)" class="tableBtn"
                            style="background-color: #337ab7; border: #337ab7;color: snow;"
                            *ngIf="element.statusDescription !== 'Missing Data Info' && element.statusDescription !== 'Draft' && element.statusDescription !== 'Submitted' && element.statusDescription !== 'Resubmitted'">
                            <mat-icon>description</mat-icon>
                        </button>
                        <ng-container *ngIf="element.statusDescription !== 'Deleted'">
                            <button (click)="openForm(element, false)" class="tableBtn"
                                style="background-color: #337ab7; border: #337ab7;color: snow;"
                                *ngIf="element.statusDescription === 'Missing Data Info' || element.statusDescription === 'Draft' || element.statusDescription === 'Submitted' || element.statusDescription === 'Resubmitted'">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button (click)="delete(element)" class="tableBtn"
                                style="background-color: #800909; border: #800909;color: snow;"
                                *ngIf="element.statusDescription === 'Draft'">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container>

                    </ng-container>

                </td>



            </ng-container>

            <!-- Filter Table Headers -->
            <ng-container matColumnDef="idFilter">
                <th class="idColumn" mat-header-cell *matHeaderCellDef>
                    <mat-form-field class="idColumn">
                        <input [(ngModel)]="idFilter" matInput (keyup)="applyFilter()" placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="requestorFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="requestorFilter" matInput (keyup)="applyFilter()" placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="titleFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="titleFilter" matInput (keyup)="applyFilter()" placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="createdFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="createdFilter" matInput (keyup)="applyFilter()" placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="requestTypeDescriptionFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="requestTypeDescriptionFilter" matInput (keyup)="applyFilter()"
                            placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="requestSubTypeDescriptionFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="requestSubTypeDescriptionFilter" matInput (keyup)="applyFilter()"
                            placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="expectedDateFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="expectedDateFilter" matInput (keyup)="applyFilter()" placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="effectiveDateFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="effectiveDateFilter" matInput (keyup)="applyFilter()" placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="statusDescriptionFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="statusDescriptionFilter" matInput (keyup)="applyFilter()"
                            placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="mediaUrlFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field>
                        <input [(ngModel)]="mediaUrlFilter" matInput (keyup)="applyFilter()" placeholder="Filter">
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="clearFilter">
                <th mat-header-cell *matHeaderCellDef>
                    <button mat-button color="primary" (click)="clearFilter()">
                        <mat-icon>clear</mat-icon> Filter
                    </button>
                </th>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableHeader; sticky: true"></tr>
            <tr mat-header-row
                *matHeaderRowDef="['idFilter', 'requestorFilter','titleFilter','createdFilter','requestTypeDescriptionFilter','requestSubTypeDescriptionFilter'
            ,'expectedDateFilter','effectiveDateFilter','statusDescriptionFilter','mediaUrlFilter','clearFilter']; sticky: true">
            </tr>
            <tr mat-row *matRowDef="let row; columns: tableHeader;"></tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>