<div *ngIf="isVisible">
  <img class="gif" src="assets\img\accenture-gifLow.gif">
  <mat-toolbar class="header">
    <div>
      <button mat-icon-button routerLink="/Home">
        <mat-icon>videocam</mat-icon>
      </button>
      <a href="https://mycio.accenture.com" target="_blank" rel="noopener noreferrer" style="color: white;">
        <button mat-button>MyCIO Portal - Home Page</button>
      </a>
    </div>


    <span class="fill"></span>

    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>help</mat-icon>
    </button>
    <a href="https://people.accenture.com/Home.aspx" target="_blank" rel="noopener noreferrer" style="color: white;">
      <button mat-button>
        <mat-icon class="md-48">account_circle</mat-icon>Welcome {{username}}
      </button>
    </a>

    <button mat-button (click)="rebarAuthService.logout()">
      <mat-icon>exit_to_app</mat-icon>Log Out
    </button>
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar>

  <mat-menu #appMenu="matMenu">
    <button mat-menu-item (click)="openAbout()">About</button>
  </mat-menu>


  <mat-sidenav-container hasBackdrop="true">
    <mat-sidenav #sidenav position="end">

      <mat-nav-list>
        <a mat-list-item routerLink="Home">
          <mat-icon>home</mat-icon> Home
        </a>
        <a *ngIf="userService.isAdministrator || userService.isTCTeam" mat-list-item (click)="navigateToRequests()">
          <mat-icon>credit_card</mat-icon> All Requests
        </a>

        <mat-expansion-panel *ngIf="userService.isAdministrator || userService.isDeveloper"
                             (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header style="padding: 0px 16px;">
            <mat-panel-title>
              <mat-icon>person</mat-icon> Aministration
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="Home">
            <mat-icon>dashboard</mat-icon>Dashboard
          </a>
          <a mat-list-item routerLink="/Admin/User">
            <mat-icon>people</mat-icon>Users
          </a>
          <a mat-list-item routerLink="/Admin/MailTemplate">
            <mat-icon>mail_outline</mat-icon>Mail Template
          </a>
          <hr>
          <p>Data Management</p>
          <a mat-list-item class="text-nowrap" routerLink="/Admin/RequestType">
            <mat-icon>settings_applications</mat-icon>Request Type
          </a>
          <a mat-list-item class="text-nowrap" routerLink="/Admin/RequestSubType">
            <mat-icon>settings_applications</mat-icon>Request SubType
          </a>
          <a mat-list-item class="text-nowrap" routerLink="/Admin/Location">
            <mat-icon>settings_applications</mat-icon>Location
          </a>
          <a mat-list-item class="text-nowrap" routerLink="/Admin/Office">
            <mat-icon>settings_applications</mat-icon>Office
          </a>
          <a mat-list-item class="text-nowrap" routerLink="/Admin/ProjectResponsible">
            <mat-icon>settings_applications</mat-icon>Project Responsible
          </a>
          <hr>
          <div *ngIf="userService.isDeveloper">
            <p>Developer Tools</p>
            <a mat-list-item class="text-nowrap" routerLink="/Admin/Settings">
              <mat-icon>settings_applications</mat-icon>Settings
            </a>
            <a mat-list-item class="text-nowrap" routerLink="/Admin/ErrorLog">
              <mat-icon>report_problem</mat-icon>Error Log
            </a>
            <a mat-list-item class="text-nowrap" routerLink="/Admin/EventLog">
              <mat-icon>info</mat-icon>Event Log
            </a>
          </div>
        </mat-expansion-panel>

      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fullscreen>
      <span class="spacer"><br /></span>
      <div *ngIf="isLoading">Loading User Data...</div>
      <router-outlet *ngIf="!isLoading" class="router-outlet" style="opacity: 0;"></router-outlet>

    </mat-sidenav-content>

  </mat-sidenav-container>

  <mat-toolbar class="footer">
    <a mat-button target="_blank" href="https://in.accenture.com/protectingaccenture/data-security/5422-2/">Privacy
      Policy</a>
    <a mat-button target="_blank" href="https://www.accenture.com/us-en/company-cookies-similar-technology">Cookie
      Policy</a>
    <a mat-button routerLink="terms">Terms of Use</a>

    <span class="fill"></span>

    <p>
      &copy; {{ year }} Accenture. All rights reserved. Accenture Confidential. For internal use only.</p>
  </mat-toolbar>
</div>
