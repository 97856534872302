<p>Are you sure you want to delete the selected Request? ID {{data.id}}</p>

<div class="buttons">
<button mat-button color="primary" (click)="delete()" cdkFocusInitial>
    <mat-icon>check</mat-icon> Delete
    </button>

<button mat-button color="primary" [mat-dialog-close]="false">
    <mat-icon>clear</mat-icon> Cancel
</button>
</div>