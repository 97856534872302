import { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataBaseService } from '../../core/services/data-base.service';

export interface DialogData {
  id: number;
}

@Component({
  selector: 'rebar-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.css'],
})
export class DeletePopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeletePopupComponent>,
    private dataBaseService: DataBaseService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {}

  delete() {
    this.dataBaseService.deleteRequestById(this.data.id).subscribe(
      (arg) => {
        this._snackBar.open('Request deleted successfully', '', {
          duration: 5000,
        });
        this.dialogRef.close(true);
      },
      (err) => {
        this._snackBar.open('Error' + err.status, '', {
          duration: 5000,
        });
        this.dialogRef.close(false);
      }
    );
  }
}
