import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { BetaComponent } from './shared/beta/beta.component';
import { MsalGuard } from '@azure/msal-angular';
import { REBAR_AUTH_GUARD } from './core/rebarauth/rebar.auth.module';

import { HomeComponent } from './feature/home/home.component';
import { RequestViewComponent } from './feature/request-view/request-view.component';
import { AdministrationComponent } from './feature/administration/administration.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { FormComponent } from './feature/form/form.component';
import { FormEditViewComponent } from './feature/form-edit-view/form-edit-view.component';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'Home',
  },
  {
    path: 'Home',
    component: HomeComponent,
    canActivate: [REBAR_AUTH_GUARD],
  },
  {
    path: 'RequestView',
    component: RequestViewComponent,
    canActivate: [REBAR_AUTH_GUARD],
  },
  {
    path: 'Form',
    component: FormComponent,
    canActivate: [REBAR_AUTH_GUARD],
  },
  {
    path: 'FormEditView',
    component: FormEditViewComponent,
    canActivate: [REBAR_AUTH_GUARD],
  },
  {
    path: 'Admin',
    loadChildren: () =>
      import('./feature/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
    canActivate: [REBAR_AUTH_GUARD],
  },

  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '401',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },

  // Work in progress page

  // {
  //     path: '',
  //     pathMatch: 'full',
  //     component: BetaComponent,
  //     canActivate: [REBAR_AUTH_GUARD]
  // },
  // {
  //     path: '**',
  //     redirectTo: '',
  // }
];
export const routing: ModuleWithProviders<any> =
  RouterModule.forRoot(appRoutes);
