import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AddApiDomainInterceptorService implements HttpInterceptor {
  apiUrl: string;

  constructor(private appConfigService: AppConfigService) {
    this.apiUrl = this.appConfigService.config['apiUrl'] as string;
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const modifiedRequest = req.clone({
      url: this.apiUrl + '/' + req.url,
    });

    return next.handle(modifiedRequest);
  }
}
