import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataBaseService } from '../../core/services/data-base.service';
import { UserService } from '../../core/services/user.service';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Model from '../../core/Models/Models';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidatorFn,
  UntypedFormArray,
} from '@angular/forms';
// People picker Imports
import {
  map,
  startWith,
  debounceTime,
  tap,
  switchMapTo,
  switchMap,
  finalize,
  filter,
  take,
  distinctUntilChanged
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { timer } from 'rxjs-compat/observable/timer';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CollectionFlagPopupComponent } from '../../shared/collection-flag-popup/collection-flag-popup.component';
import { DeletePopupComponent } from '../../shared/delete-popup/delete-popup.component';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'rebar-form-edit-view',
  templateUrl: './form-edit-view.component.html',
  styleUrls: ['./form-edit-view.component.css'],
})
export class FormEditViewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private dataBaseService: DataBaseService,
    public userService: UserService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      if (params.isView == 'true') {
        this.isView = true;
        this.isEdit = false;
        this.isDisabled = true;
      }
      this.id = params.id;
    });
  }

  action: string;
  isView = false;
  isEdit = true;
  id: number;
  request: Model.RequestDtoIn;
  loading = true;
  loadingFail = false;
  requestorProfile = null;
  hasAccess = true;

  progress = '';
  uploading = false;
  requestIn = new FormData();
  requestTypes: Model.RequestType[] = [{ id: 1, description: 'Loading' }];
  requestSubTypes: Model.RequestSubType[] = [
    { id: 1, description: 'Loading', requestTypeId: 1, requestType: null },
  ];
  filteredRequestSubTypes: Model.RequestSubType[] = [
    { id: 1, description: 'Loading', requestTypeId: 1, requestType: null },
  ];
  speechFiles = [];
  disclaimerFiles = [];
  offices: Model.Office[] = [
    { id: 1, description: 'Loading', isExternal: false },
  ];
  locations: Model.Location[] = [
    {
      id: 1,
      description: 'Loading',
      isExternal: false,
      officeId: 1,
      office: null,
    },
  ];
  filteredLocations: Model.Location[] = [
    {
      id: 1,
      description: 'Loading',
      isExternal: false,
      officeId: 1,
      office: null,
    },
  ];
  projectResponsibles: Model.ProjectResponsible[] = [
    { id: 1, enterpriseId: 'Loading...' },
  ];

  istockUrl = 'https://www.istockphoto.com/it';
  brandspaceUrl = 'https://ts.accenture.com/sites/BrandSpace/';
  logoUrl = 'https://ts.accenture.com/sites/BrandSpace/downloadableartwork';
  BF1Selected = '';
  BF2Selected = '';
  BF3Selected = '';
  SFSelected = '';

  statusDescription = {
    1: 'Draft',
    2: 'Submitted',
    3: 'Approved',
    4: 'Rejected by TC Team',
    5: 'Missing Data Info',
    6: 'Answered',
    7: 'Resubmitted',
    8: 'Completed',
    9: 'Closed',
    10: 'Deleted',
  };

  lastStatusId = null;
  isDisabled = false;

  subtitlesForm;
  speechFileNamesForm;
  disclaimerFileNamesForm;
  requestForm = new UntypedFormGroup({});

  // approver People Picker
  // FILTER PEOPLE

  approveroptions: any;
  approverfilteredOptions: any;
  approverisLoading = false;


  // requestTeam People Picker
  // FILTER PEOPLE

  requestTeamoptions: any;
  requestTeamfilteredOptions: any;
  requestTeamisLoading = false;
  // requestTeam people picker end

  // internalActor People Picker
  // FILTER PEOPLE
  internalActoroptions: any;
  internalActorfilteredOptions: any;
  internalActorisLoading = false;

  ngOnInit() {
    this.requestForm = new UntypedFormGroup(
      {
        title: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(2),
        ]),
        approver: new UntypedFormControl(
          '',
          [Validators.required, Validators.minLength(3)],
          [this.validatePerson.bind(this), this.validateLevel.bind(this)]
        ),
        requestTeam: new UntypedFormControl(
          '',
          [Validators.minLength(3)],
          this.validatePerson.bind(this)
        ),
        requestTypeId: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(2),
        ]),
        requestSubTypeId: new UntypedFormControl(''),
        effectiveDate: new UntypedFormControl(null, [
          this.validateEffectiveDateDate.bind(this),
        ]),
        expectedDate: new UntypedFormControl(null, [
          Validators.required,
          this.validateExpectedDate.bind(this),
        ]),
        typeDetails: new UntypedFormControl('', [Validators.maxLength(300)]),
        wbs: new UntypedFormControl(
          '',
          [Validators.maxLength(10)],
          this.validateWbs.bind(this)
        ),
        isExternal: new UntypedFormControl('0'),
        internalActor: new UntypedFormControl(
          '',
          [Validators.minLength(3)],
          this.validatePerson.bind(this)
        ),
        externalActor: new UntypedFormControl('', [Validators.maxLength(50)]),
        subtitles: new UntypedFormArray([], { validators: Validators.maxLength(255) }),
        speechFileNames: new UntypedFormArray([], {
          validators: Validators.maxLength(255),
        }),
        disclaimerFileNames: new UntypedFormArray([], {
          validators: Validators.maxLength(255),
        }),
        office: new UntypedFormControl('', [Validators.required]),
        location: new UntypedFormControl('', [Validators.maxLength(100)]),
        backgroundFileName1: new UntypedFormControl(null, [
          Validators.maxLength(1000),
          this.validateFileExtension('.png,.bmp,.jpg,.mp4,.zip').bind(this),
        ]),
        backgroundFileName2: new UntypedFormControl(null, [
          Validators.maxLength(1000),
          this.validateFileExtension('.png,.bmp,.jpg,.mp4,.zip').bind(this),
        ]),
        backgroundFileName3: new UntypedFormControl(null, [
          Validators.maxLength(1000),
          this.validateFileExtension('.png,.bmp,.jpg,.mp4,.zip').bind(this),
        ]),
        backgroundLink1: new UntypedFormControl(null, [Validators.maxLength(1000)]),
        backgroundLink2: new UntypedFormControl(null, [Validators.maxLength(1000)]),
        backgroundLink3: new UntypedFormControl(null, [Validators.maxLength(1000)]),
        itemsFileName1: new UntypedFormControl(null, [
          Validators.maxLength(1000),
          this.validateFileExtension('.pdf,.png,.bmp,.jpg,.mp4,.zip').bind(
            this
          ),
        ]),
        itemsFileName2: new UntypedFormControl(null, [
          Validators.maxLength(1000),
          this.validateFileExtension('.pdf,.png,.bmp,.jpg,.mp4,.zip').bind(
            this
          ),
        ]),
        itemsLink1: new UntypedFormControl({ value: null, disabled: true }, [
          Validators.maxLength(1000),
        ]),
        itemsLink2: new UntypedFormControl({ value: null, disabled: true }, [
          Validators.maxLength(1000),
        ]),
        itemsLink3: new UntypedFormControl({ value: null, disabled: true }, [
          Validators.maxLength(1000),
        ]),
        itemsLink4: new UntypedFormControl({ value: null, disabled: true }, [
          Validators.maxLength(1000),
        ]),
        soundtrackFileName: new UntypedFormControl(null, [
          Validators.maxLength(1000),
          this.validateFileExtension('.mp3').bind(this),
        ]),
        soundtrackLink: new UntypedFormControl(null, [Validators.maxLength(1000)]),
        finalAnimatedLogo: new UntypedFormControl(null, [Validators.maxLength(1000)]),
        collectionLink: new UntypedFormControl(null, [Validators.maxLength(1000)]),
        hasThemeSong: new UntypedFormControl(null),
        themeSongFileName: new UntypedFormControl(null, [Validators.maxLength(1000)]),
        collectionFlag: new UntypedFormControl(null),
        mediaUrl: new UntypedFormControl(null, [Validators.maxLength(1000)]),
        projectResponsible: new UntypedFormControl(null, [Validators.maxLength(50)]),
        requestHistoryLogComment: new UntypedFormControl(null, [
          Validators.maxLength(5000),
        ]),
        requestAdminLogComment: new UntypedFormControl('', [
          Validators.maxLength(5000),
        ]),
      },
      [this.validateItems.bind(this)]
    );

    this.subtitlesForm = this.requestForm.get('subtitles') as UntypedFormArray;
    this.speechFileNamesForm = this.requestForm.get(
      'speechFileNames'
    ) as UntypedFormArray;
    this.disclaimerFileNamesForm = this.requestForm.get(
      'disclaimerFileNames'
    ) as UntypedFormArray;

    this.dataBaseService.getRequestById(this.id).subscribe(
      (arg) => {
        console.log(arg);
        this.request = arg.body;
        console.log(this.request);

        if (
          this.userService.isAdministrator ||
          this.userService.isDeveloper ||
          this.userService.isTCTeam
        ) {
          if (
            this.isView &&
            !(
              this.statusDescription[this.request.statusId] == 'Closed' ||
              this.statusDescription[this.request.statusId] ==
                'Rejected by TC Team' ||
              this.statusDescription[this.request.statusId] == 'Completed' ||
              this.statusDescription[this.request.statusId] == 'Draft' ||
              this.statusDescription[this.request.statusId] == 'Deleted'
            )
          ) {
            this.hasAccess = false;
            this.loading = false;
            return;
          }
          if (
            this.isEdit &&
            (this.statusDescription[this.request.statusId] == 'Deleted' ||
              !(
                (this.statusDescription[this.request.statusId] != 'Closed' &&
                  this.statusDescription[this.request.statusId] !=
                    'Rejected by TC Team' &&
                  this.statusDescription[this.request.statusId] !=
                    'Completed' &&
                  this.statusDescription[this.request.statusId] != 'Draft') ||
                (this.statusDescription[this.request.statusId] == 'Draft' &&
                  this.userService.User.enterpriseId == this.request.requestor)
              ))
          ) {
            this.hasAccess = false;
            this.loading = false;
            return;
          }
        } else {
          if (
            this.isView &&
            !(
              this.statusDescription[this.request.statusId] !=
                'Missing Data Info' &&
              this.statusDescription[this.request.statusId] != 'Draft' &&
              this.statusDescription[this.request.statusId] != 'Submitted' &&
              this.statusDescription[this.request.statusId] != 'Resubmitted'
            )
          ) {
            this.hasAccess = false;
            this.loading = false;
            return;
          }
          if (
            this.isEdit &&
            (this.statusDescription[this.request.statusId] == 'Deleted' ||
              !(
                this.statusDescription[this.request.statusId] ==
                  'Missing Data Info' ||
                this.statusDescription[this.request.statusId] == 'Draft' ||
                this.statusDescription[this.request.statusId] == 'Submitted' ||
                this.statusDescription[this.request.statusId] == 'Resubmitted'
              ))
          ) {
            this.hasAccess = false;
            this.loading = false;
            return;
          }
        }

        if (this.request.statusId == 6) {
          for (let i = this.request.requestHistoryLog.length - 2; i >= 0; i--) {
            if (
              this.request.requestHistoryLog[i].description.includes(
                'Submitted'
              )
            ) {
              this.lastStatusId = 2;
              console.log(this.statusDescription[2]);
              break;
            }
            if (
              this.request.requestHistoryLog[i].description.includes(
                'Resubmitted'
              )
            ) {
              this.lastStatusId = 7;
              console.log(this.statusDescription[7]);
              break;
            }
            if (
              this.request.requestHistoryLog[i].description.includes('Approved')
            ) {
              this.lastStatusId = 3;
              console.log(this.statusDescription[3]);
              break;
            }
          }
        }

        if (arg.body.requestorActor) {
          for (let i = 0; i < arg.body.requestorActor.length; i++) {
            const element = arg.body.requestorActor[i];
            this.subtitlesForm.push(
              new UntypedFormControl('', Validators.maxLength(255))
            );
            this.subtitlesForm.controls[i].setValue(element.subtitles);
            this.speechFileNamesForm.push(
              new UntypedFormControl('', Validators.maxLength(255))
            );
            this.speechFileNamesForm.controls[i].setValue(
              element.speechFileName
            );
            this.disclaimerFileNamesForm.push(
              new UntypedFormControl('', Validators.maxLength(255))
            );
            this.disclaimerFileNamesForm.controls[i].setValue(
              element.disclaimerFileName
            );
          }
        }

        this.requestForm.controls.title.setValue(this.request.title);
        this.requestForm.controls.approver.setValue(this.request.approver);
        this.requestForm.controls.requestTypeId.setValue(
          this.request.requestTypeId
        );
        this.requestForm.controls.requestSubTypeId.setValue(
          this.request.requestSubTypeId
        );
        this.requestForm.controls.effectiveDate.setValue(
          this.request.effectiveDate
        );
        this.requestForm.controls.expectedDate.setValue(
          this.request.expectedDate
        );
        this.requestForm.controls.typeDetails.setValue(
          this.request.typeDetails
        );
        this.requestForm.controls.wbs.setValue(this.request.wbs);
        this.requestForm.controls.location.setValue(this.request.location);

        if (this.request.requestorMaterial[0].backgroundFileName1) {
          this.requestForm.controls.backgroundFileName1.setValue(
            this.request.requestorMaterial[0].backgroundFileName1
          );
          this.BF1Selected = 'file';
        } else if (this.request.requestorMaterial[0].backgroundLink1) {
          this.requestForm.controls.backgroundLink1.setValue(
            this.request.requestorMaterial[0].backgroundLink1
          );
          this.BF1Selected = 'link';
        }

        if (this.request.requestorMaterial[0].backgroundFileName2) {
          this.requestForm.controls.backgroundFileName2.setValue(
            this.request.requestorMaterial[0].backgroundFileName2
          );
          this.BF2Selected = 'file';
        } else if (this.request.requestorMaterial[0].backgroundLink2) {
          this.requestForm.controls.backgroundLink2.setValue(
            this.request.requestorMaterial[0].backgroundLink2
          );
          this.BF2Selected = 'link';
        }

        if (this.request.requestorMaterial[0].backgroundFileName3) {
          this.requestForm.controls.backgroundFileName3.setValue(
            this.request.requestorMaterial[0].backgroundFileName3
          );
          this.BF3Selected = 'file';
        } else if (this.request.requestorMaterial[0].backgroundLink3) {
          this.requestForm.controls.backgroundLink3.setValue(
            this.request.requestorMaterial[0].backgroundLink3
          );
          this.BF3Selected = 'link';
        }

        this.requestForm.controls.itemsFileName1.setValue(
          this.request.requestorMaterial[0].itemsFileName1
        );
        this.requestForm.controls.itemsFileName2.setValue(
          this.request.requestorMaterial[0].itemsFileName2
        );
        this.requestForm.controls.itemsLink1.setValue(
          this.request.requestorMaterial[0].itemsLink1
        );
        this.requestForm.controls.itemsLink2.setValue(
          this.request.requestorMaterial[0].itemsLink2
        );
        this.requestForm.controls.itemsLink3.setValue(
          this.request.requestorMaterial[0].itemsLink3
        );
        this.requestForm.controls.itemsLink4.setValue(
          this.request.requestorMaterial[0].itemsLink4
        );

        if (this.request.requestorMaterial[0].soundtrackFileName) {
          this.requestForm.controls.soundtrackFileName.setValue(
            this.request.requestorMaterial[0].soundtrackFileName
          );
          this.SFSelected = 'file';
        } else if (this.request.requestorMaterial[0].soundtrackLink) {
          this.requestForm.controls.soundtrackLink.setValue(
            this.request.requestorMaterial[0].soundtrackLink
          );
          this.SFSelected = 'link';
        }

        this.requestForm.controls.finalAnimatedLogo.setValue(
          this.request.requestorMaterial[0].finalAnimatedLogo
        );
        this.requestForm.controls.collectionLink.setValue(
          this.request.collectionLink
        );
        this.requestForm.controls.hasThemeSong.setValue(
          this.request.requestorMaterial[0].hasThemeSong
        );
        this.requestForm.controls.themeSongFileName.setValue(
          this.request.requestorMaterial[0].themeSongFileName
        );
        this.requestForm.controls.collectionFlag.setValue(
          this.request.collectionFlag
        );

        if (this.request.statusId != 2 && this.request.statusId != 5) {
          this.requestForm.controls.collectionFlag.disable();
          this.requestForm.controls.hasThemeSong.disable();
        }

        this.requestForm.controls.mediaUrl.setValue(this.request.mediaUrl);
        this.requestForm.controls.projectResponsible.setValue(
          this.request.projectResponsible
        );

        this.dataBaseService.getProfile(this.request.requestor).subscribe(
          (arg) => {
            console.log(arg);
            this.requestorProfile = arg;
            if (arg == null) {
              this.requestorProfile = {
                firstName: 'No Data',
                lastName: 'No Data',
                personnelNumber: 'No Data',
                levelDescription: 'No Data',
                jobFamilyDescription: 'No Data',
                location: 'No Data',
                companyCode: 'No Data',
                companyDescription: 'No Data',
                costCenterCode: 'No Data',
                costCenterDescription: 'No Data',
                countryDescription: 'No Data',
              };
            }
            this.loading = false;
          },
          (err) => {
            console.log(err);
            this.isDisabled = true;
            this.requestTypes[0].description = 'Failed to Load';
            this._snackBar.open('Error ' + err.status, err.error.title, {
              duration: 5000,
            });
          }
        );

        this.dataBaseService.getRequestTypes().subscribe(
          (arg) => {
            console.log(arg);
            this.requestTypes = arg;
          },
          (err) => {
            console.log(err);
            this.isDisabled = true;
            this.requestTypes[0].description = 'Failed to Load';
            this._snackBar.open('Error ' + err.status, err.error.title, {
              duration: 5000,
            });
          }
        );

        this.dataBaseService.getRequestSubTypes().subscribe(
          (arg) => {
            console.log(arg);
            this.requestSubTypes = arg;
            this.filteredRequestSubTypes = this.requestSubTypes.filter(
              (r) => r.requestTypeId == this.requestForm.value.requestTypeId
            );
          },
          (err) => {
            console.log(err);
            this.isDisabled = true;
            this.requestSubTypes[0].description = 'Failed to Load';
            this._snackBar.open('Error ' + err.status, err.error.title, {
              duration: 5000,
            });
          }
        );

        this.dataBaseService.getOffices().subscribe(
          (arg) => {
            console.log(arg);
            this.offices = arg;
            this.requestForm.controls.office.setValue(
              this.offices.find(
                (element) => element.id == this.request.officeId
              )
            );
            this.requestForm.value.office.isExternal = true;
          },
          (err) => {
            console.log(err);
            this.isDisabled = true;
            this.offices[0].description = 'Failed to Load';
            this._snackBar.open('Error ' + err.status, err.error.title, {
              duration: 5000,
            });
          }
        );

        this.dataBaseService.getLocations().subscribe(
          (arg) => {
            console.log(arg);
            this.locations = arg;
            this.filteredLocations = this.locations.filter(
              (r) => r.officeId == this.requestForm.value.office.id
            );
          },
          (err) => {
            console.log(err);
            this.isDisabled = true;
            this.locations[0].description = 'Failed to Load';
            this._snackBar.open('Error ' + err.status, err.error.title, {
              duration: 5000,
            });
          }
        );

        this.dataBaseService.getProjectResponsibles().subscribe(
          (arg) => {
            console.log(arg);
            this.projectResponsibles = arg;
          },
          (err) => {
            console.log(err);
            this.isDisabled = true;
            this.locations[0].description = 'Failed to Load';
            this._snackBar.open('Error ' + err.status, err.error.title, {
              duration: 5000,
            });
          }
        );

        if (this.isView) {
          this.requestForm.disable();
        }
      },
      (err) => {
        console.log(err);
        this.loadingFail = true;
        this._snackBar.open(
          'Error ' + err.status + '  Failed to Load',
          err.error.title,
          {
            duration: 5000,
          }
        );
      }
    );

    // approver People Picker
    this.requestForm.controls.approver.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        filter((value) => !!value && value.length > 2),
        switchMap((value) => 
        {
          this.approveroptions = [];
          this.approverisLoading = true;
          return this.dataBaseService.getFilteredPeople(value)
        })
      )
      .subscribe(
        (people) => {
          this.approverisLoading = false;
          if (people) {
            this.approveroptions = people;
          } else {
            this.approveroptions = [];
          }
        },
        (err) => {
          console.log(err);
          this._snackBar.open('Error ' + err.status, err.error.title, {
            duration: 5000,
          });
        }
      );
    // approver People Picker end

    // requestTeam People Picker
    this.requestForm.controls.requestTeam.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        filter((value) => value.length > 2),
        switchMap((value) => 
        {
          this.requestTeamoptions = [];
          this.requestTeamisLoading = true;
          return this.dataBaseService.getFilteredPeople(value)
        })
      )
      .subscribe(
        (people) => {
          this.requestTeamisLoading = false;
          if (people) {
            this.requestTeamoptions = people;
          } else {
            this.requestTeamoptions = [];
          }
        },
        (err) => {
          console.log(err);
          this._snackBar.open('Error ' + err.status, err.error.title, {
            duration: 5000,
          });
        }
      );
    // requestTeam People Picker end

    // internalActor People Picker
    this.requestForm.controls.internalActor.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        filter((value) => value.length > 2),
        switchMap((value) =>
        { 
          this.internalActoroptions = [];
          this.internalActorisLoading = true;
          return this.dataBaseService.getFilteredPeople(value)
        })
      )
      .subscribe(
        (people) => {
          this.internalActorisLoading = false;
          if (people) {
            this.internalActoroptions = people;
          } else {
            this.internalActoroptions = [];
          }
        },
        (err) => {
          console.log(err);
          this._snackBar.open('Error ' + err.status, err.error.title, {
            duration: 5000,
          });
        }
      );
    // internalActor People Picker end
  }

  validateItems(c: UntypedFormGroup) {
    let brandspace = 0;
    let istock = 0;
    for (let index = 1; index < 5; index++) {
      if (
        c.controls['itemsLink' + index].value &&
        c.controls['itemsLink' + index].value.includes(
          'brandspace.accenture.com'
        )
      ) {
        brandspace++;
      }
      if (
        c.controls['itemsLink' + index].value &&
        c.controls['itemsLink' + index].value.includes('istockphoto.com')
      ) {
        istock++;
      }
    }

    return istock > 2 || brandspace > 2
      ? {
          validateItems:
            'Please check your Items selection. No more than 2 for each category (file, brandspace, istock) is allowed',
        }
      : null;
  }

  validateWbs(c: UntypedFormControl) {
    // A0005001
    if (c.value && c.value.length != 0) {
      return timer(600).pipe(
        switchMapTo(this.dataBaseService.isValidWbs(c.value)),
        map((result) => (result.body ? null : { validateWbs: 'Invalid WBS' })),
        take(1));

      }
    return of(null);
  }

  validateFileExtension(extensions: string) {
    return (c: UntypedFormControl) => {
      if (c.value) {
        c.markAllAsTouched();
        return extensions.includes(c.value.slice(-3))
          ? null
          : {
              validateFileExtension:
                ' The selected file has an invalid extension',
            };
      }
      return null;
    };
  }

  validateExpectedDate(c: UntypedFormControl): any {
    if (!this.loading) {
      const creationDate = new Date(this.request.created);
      creationDate.setDate(creationDate.getDate() + 10);
      const expectedDate = new Date(c.value);
      return creationDate < expectedDate
        ? null
        : {
            validateExpectedDate:
              'The expected date must be 10 days later than the creation date',
          };
    }
    return null;
  }

  validateEffectiveDateDate(c: UntypedFormControl): any {
    if (!this.loading && c.value) {
      const expectedDate = new Date(this.requestForm.value.expectedDate);
      const effectiveDate = new Date(c.value);
      return expectedDate <= effectiveDate
        ? null
        : {
            validateEffectiveDate:
              'Confirmed Date cannot be earlier than Required Date',
          };
    }
  }

  getPdf(fileId: number) {
    this.dataBaseService.getPdf(fileId).subscribe(
      (response) => {
        console.log(response);
        const fileName = response.headers
          .get('Content-Disposition')
          .split(';')[1]
          .trim()
          .split('=')[1]
          .replace(/"/g, '');
        console.log(fileName);
        const element = document.createElement('a');
        element.href = URL.createObjectURL(response.body);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        URL.revokeObjectURL(element.href);
      },
      (err) => {
        console.log(err);
        this._snackBar.open('Error ' + err.status, 'File not found', {
          duration: 5000,
        });
      }
    );
  }

  downloadFile(fileName: string, fileType: string, actorIndex: number) {
    this.dataBaseService.downloadFile(this.id, fileType, actorIndex).subscribe(
      (response) => {
        console.log(response);
        // let fileName = response.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].toString();
        console.log(fileName);
        const element = document.createElement('a');
        element.href = URL.createObjectURL(response.body);
        // element.download = "te st.txt";
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        URL.revokeObjectURL(element.href);
      },
      (err) => {
        console.log(err);
        this._snackBar.open('Error ' + err.status, 'File not found', {
          duration: 5000,
        });
      }
    );
  }

  DeleteById() {
    this.dataBaseService
      .deleteRequest(this.id)
      .subscribe((arg) => console.log(arg));
  }

  // people picker validate userid

  validatePerson(c: UntypedFormControl): any {
    if (c.value.length == 0) {
      return of(true);
    }
    return timer(600).pipe(
      switchMapTo(this.dataBaseService.isValidAccount(c.value)),
      map((result) => (result ? null : { validatePerson: 'Invalid Person' })),
      take(1));
  }

  validateLevel(c: UntypedFormControl): any {
    if (c.value.length == 0) {
      return of(true);
    }
    return timer(600).pipe(
      switchMapTo(this.dataBaseService.isValidLevel(c.value)),
      map((result) =>
        result
          ? null
          : {
              validateLevel:
                'The Sponsor must be Manager, Senior Manager or Accenture Leadership.',
            }
      ),
      take(1));
  }
  // internalActor people picker end

  requestTypeChange() {
    this.filteredRequestSubTypes = this.requestSubTypes.filter(
      (r) => r.requestTypeId == this.requestForm.value.requestTypeId
    );
    this.requestForm.controls.requestSubTypeId.reset(null);
    console.log(this.filteredRequestSubTypes);
  }

  addToReferenceTeam() {
    for (const element of this.request.requestTeam) {
      if (element.enterpriseId === this.requestForm.value.requestTeam) {
        this._snackBar.open(
          'This Employee already exists in Reference Team list',
          element.enterpriseId,
          { duration: 5000 }
        );
        this.requestForm.controls.requestTeam.setValue('');
        return;
      }
    }
    const newRequestTeam: Model.RequestTeam = {
      id: 0,
      requestId: 0,
      enterpriseId: this.requestForm.value.requestTeam,
    };
    this.request.requestTeam.push(newRequestTeam);
    this.requestForm.controls.requestTeam.setValue('');

    console.log(this.requestForm);
    console.log(this.request);
  }

  DeleteRequestTeam(i: number) {
    this.request.requestTeam.splice(i, 1);
  }

  clearActors() {
    this.requestForm.controls.externalActor.setValue('');
    this.requestForm.controls.internalActor.setValue('');
    console.log('cleared actors');
  }

  addActor() {
    if (this.request.requestorActor.length >= 5) {
      this._snackBar.open('You have reached the limit of actors', '5 Max', {
        duration: 5000,
      });
      this.clearActors();
      return;
    }
    const actorEID =
      this.requestForm.controls.externalActor.value ||
      this.requestForm.controls.internalActor.value;
    for (const element of this.request.requestorActor) {
      if (element.actor === actorEID) {
        this._snackBar.open(
          'This person already exists in Actors list',
          actorEID,
          { duration: 5000 }
        );
        this.clearActors();
        return;
      }
    }

    const actor: Model.RequestorActorDtoIn = {
      id: 0,
      requestId: this.request.id,
      actor: actorEID,
      isExternal: !!Number(this.requestForm.controls.isExternal.value),
      details: null,
      subtitles: null,
      hasSubtitles: false,
      speechFileName: null,
      disclaimerFileName: null,
    };
    this.request.requestorActor.push(actor);
    this.speechFiles.push(null);
    this.disclaimerFiles.push(null);
    this.clearActors();
    console.log(this.request.requestorActor);

    this.subtitlesForm.push(new UntypedFormControl('', Validators.maxLength(255)));
    this.speechFileNamesForm.push(
      new UntypedFormControl('', Validators.maxLength(255))
    );
    this.disclaimerFileNamesForm.push(
      new UntypedFormControl('', Validators.maxLength(255))
    );
  }

  validateFileSize(file, sizeMB: number) {
    if (file.size > sizeMB * 1000000) {
      this._snackBar.open(
        'File must be smaller than ' + sizeMB + 'MB',
        'ERROR',
        {
          duration: 5000,
        }
      );
      return false;
    }
    return true;
  }

  addSpeechFile(event, i) {
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.speechFiles[i] = event.target.files[0];
      this.speechFileNamesForm.controls[i].setValue(event.target.files[0].name);
    }
    console.log(this.speechFiles);
  }

  clearSpeechFile(i: number) {
    this.speechFiles[i] = null;
    this.speechFileNamesForm.controls[i].reset();
    console.log(this.speechFiles);
  }

  addDisclaimerFile(event, i) {
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.disclaimerFiles[i] = event.target.files[0];
      this.disclaimerFileNamesForm.controls[i].setValue(
        event.target.files[0].name
      );
      console.log(this.disclaimerFiles);
    }
  }

  clearDisclaimerFile(i: number) {
    this.disclaimerFiles[i] = null;
    this.disclaimerFileNamesForm.controls[i].reset();
    console.log(this.disclaimerFiles);
  }

  DeleteActor(i: number) {
    this.request.requestorActor.splice(i, 1);
    this.speechFiles.splice(i, 1);
    this.disclaimerFiles.splice(i, 1);
    this.subtitlesForm.removeAt(i);
    this.speechFileNamesForm.removeAt(i);
    this.disclaimerFileNamesForm.removeAt(i);
  }

  officeChange() {
    console.log(this.requestForm.value);

    this.filteredLocations = this.locations.filter(
      (r) => r.officeId == this.requestForm.value.office.id
    );
    this.requestForm.controls.location.reset(null);
  }

  openUrl(url) {
    if (url == 'istock') {
      window.open(this.istockUrl, '_blank');
    } else if (url == 'brandspace') {
      window.open(this.brandspaceUrl, '_blank');
    } else if (url == 'logo') {
      window.open(this.logoUrl, '_blank');
    }
  }

  BF1file(event) {
    this.BF1clear();
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.BF1Selected = 'file';
      this.requestForm.controls.backgroundFileName1.setValue(
        event.target.files[0].name
      );
      this.requestIn.set('BackgroundFile1', event.target.files[0]);
    }
    console.log(this.requestForm.value);
    console.log(this.requestIn.getAll('BackgroundFile1'));
  }

  BF1link(url) {
    this.openUrl(url);
    this.BF1clear();
    this.BF1Selected = 'link';
    console.log(this.requestForm.value);
  }

  BF1clear() {
    this.requestForm.controls.backgroundLink1.setValue(null);
    this.requestForm.controls.backgroundFileName1.setValue(null);
    this.requestIn.set('BackgroundFile1', null);
  }

  BF2file(event) {
    this.BF2clear();
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.BF2Selected = 'file';
      this.requestForm.controls.backgroundFileName2.setValue(
        event.target.files[0].name
      );
      this.requestIn.set('BackgroundFile2', event.target.files[0]);
    }
    console.log(this.requestForm.value);
    console.log(this.requestIn.getAll('BackgroundFile2'));
  }

  BF2link(url) {
    this.openUrl(url);
    this.BF2clear();
    this.BF2Selected = 'link';
    console.log(this.requestForm.value);
  }

  BF2clear() {
    this.requestForm.controls.backgroundLink2.setValue(null);
    this.requestForm.controls.backgroundFileName2.setValue(null);
    this.requestIn.set('BackgroundFile2', null);
  }

  BF3file(event) {
    this.BF3clear();
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.BF3Selected = 'file';
      this.requestForm.controls.backgroundFileName3.setValue(
        event.target.files[0].name
      );
      this.requestIn.set('BackgroundFile3', event.target.files[0]);
    }
    console.log(this.requestForm.value);
    console.log(this.requestIn.getAll('BackgroundFile3'));
  }

  BF3link(url) {
    this.openUrl(url);
    this.BF3clear();
    this.BF3Selected = 'link';
    console.log(this.requestForm.value);
  }

  BF3clear() {
    this.requestForm.controls.backgroundLink3.setValue(null);
    this.requestForm.controls.backgroundFileName3.setValue(null);
    this.requestIn.set('BackgroundFile3', null);
  }

  IF1file(event) {
    this.IF1clear();
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.requestForm.controls.itemsFileName1.setValue(
        event.target.files[0].name
      );
      this.requestIn.set('ItemsFile1', event.target.files[0]);
    }
    console.log(this.requestForm.value);
    console.log(this.requestIn.getAll('ItemsFile1'));
  }

  IF1clear() {
    this.requestForm.controls.itemsFileName1.setValue(null);
    this.requestIn.set('ItemsFile1', null);
  }

  IF2file(event) {
    this.IF2clear();
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.requestForm.controls.itemsFileName2.setValue(
        event.target.files[0].name
      );
      this.requestIn.set('ItemsFile2', event.target.files[0]);
    }
    console.log(this.requestForm.value);
    console.log(this.requestIn.getAll('ItemsFile2'));
  }

  IF2clear() {
    this.requestForm.controls.itemsFileName2.setValue(null);
    this.requestIn.set('ItemsFile2', null);
  }

  IL1link(url) {
    this.requestForm.controls.itemsLink1.enable();
    this.openUrl(url);
    this.IL1clear();
    console.log(this.requestForm.value);
  }

  IL1clear() {
    this.requestForm.controls.itemsLink1.setValue(null);
  }

  IL2link(url) {
    this.requestForm.controls.itemsLink2.enable();
    this.openUrl(url);
    this.IL2clear();
    console.log(this.requestForm.value);
  }

  IL2clear() {
    this.requestForm.controls.itemsLink2.setValue(null);
  }

  IL3link(url) {
    this.requestForm.controls.itemsLink3.enable();
    this.openUrl(url);
    this.IL3clear();
    console.log(this.requestForm.value);
  }

  IL3clear() {
    this.requestForm.controls.itemsLink3.setValue(null);
  }

  IL4link(url) {
    this.requestForm.controls.itemsLink4.enable();
    this.openUrl(url);
    this.IL4clear();
    console.log(this.requestForm.value);
  }

  IL4clear() {
    this.requestForm.controls.itemsLink4.setValue(null);
  }

  SFfile(event) {
    this.SFclear();
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.SFSelected = 'file';
      this.requestForm.controls.soundtrackFileName.setValue(
        event.target.files[0].name
      );
      this.requestIn.set('SoundtrackFile', event.target.files[0]);
    }
    console.log(this.requestForm.value);
    console.log(this.requestIn.getAll('SoundtrackFile'));
  }

  SFlink(url) {
    this.openUrl(url);
    this.SFclear();
    this.SFSelected = 'link';
    console.log(this.requestForm.value);
  }

  SFclear() {
    this.requestForm.controls.soundtrackLink.setValue(null);
    this.requestForm.controls.soundtrackFileName.setValue(null);
    this.requestIn.set('SoundtrackFile', null);
  }

  TSFfile(event) {
    this.TSFclear();
    if (
      event.target.files[0] &&
      this.validateFileSize(event.target.files[0], 5)
    ) {
      this.requestForm.controls.themeSongFileName.setValue(
        event.target.files[0].name
      );
      this.requestIn.set('ThemeSongFile', event.target.files[0]);
    }
    console.log(this.requestForm.value);
    console.log(this.requestIn.getAll('ThemeSongFile'));
  }

  TSFclear() {
    this.requestForm.controls.themeSongFileName.setValue(null);
    this.requestIn.set('ThemeSongFile', null);
  }

  collectionFlagChange() {
    if (this.requestForm.value.collectionFlag == true) {
      console.log('collection true');

      const dialogRef = this.dialog.open(CollectionFlagPopupComponent, {
        width: '550px',
        data: { flag: true },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.requestForm.controls.collectionFlag.setValue(result);
      });
    }
  }

  update(statusId: number) {
    this.requestForm.controls.requestTeam.setValue('');
    console.log(this.requestForm);
    // mapping start
    this.request.title = this.requestForm.value.title;
    this.request.approver = this.requestForm.value.approver;
    this.request.requestTypeId = this.requestForm.value.requestTypeId;
    this.request.requestSubTypeId = this.requestForm.value.requestSubTypeId;
    this.request.effectiveDate = this.requestForm.value.effectiveDate;
    this.request.expectedDate = this.requestForm.value.expectedDate;
    this.request.typeDetails = this.requestForm.value.typeDetails || null;
    this.request.wbs = this.requestForm.value.wbs || null;
    this.request.officeId = this.requestForm.value.office.id;
    this.request.location = this.requestForm.value.location;

    this.request.requestorActor.forEach((element, i) => {
      if (this.subtitlesForm.value[i] != '') {
        element.subtitles = this.subtitlesForm.value[i];
        element.hasSubtitles = true;
      }

      element.speechFileName = this.speechFileNamesForm.value[i] || null;
      if (this.speechFiles[i]) {
        this.requestIn.append('speechFiles', this.speechFiles[i]);
      } else {
        this.requestIn.append(
          'speechFiles',
          new Blob([''], { type: 'text/xml' })
        );
      }
      console.log(this.requestIn.getAll('speechFiles'));

      element.disclaimerFileName =
        this.disclaimerFileNamesForm.value[i] || null;
      if (this.disclaimerFiles[i]) {
        this.requestIn.append('disclaimerFiles', this.disclaimerFiles[i]);
      } else {
        this.requestIn.append(
          'disclaimerFiles',
          new Blob([''], { type: 'text/xml' })
        );
      }
      console.log(this.requestIn.getAll('disclaimerFiles'));
    });

    this.request.requestorMaterial[0].backgroundFileName1 =
      this.requestForm.value.backgroundFileName1;
    this.request.requestorMaterial[0].backgroundFileName2 =
      this.requestForm.value.backgroundFileName2;
    this.request.requestorMaterial[0].backgroundFileName3 =
      this.requestForm.value.backgroundFileName3;
    this.request.requestorMaterial[0].backgroundLink1 =
      this.requestForm.value.backgroundLink1;
    this.request.requestorMaterial[0].backgroundLink2 =
      this.requestForm.value.backgroundLink2;
    this.request.requestorMaterial[0].backgroundLink3 =
      this.requestForm.value.backgroundLink3;
    this.request.requestorMaterial[0].itemsFileName1 =
      this.requestForm.value.itemsFileName1;
    this.request.requestorMaterial[0].itemsFileName2 =
      this.requestForm.value.itemsFileName2;
    this.request.requestorMaterial[0].itemsLink1 =
      this.requestForm.value.itemsLink1;
    this.request.requestorMaterial[0].itemsLink2 =
      this.requestForm.value.itemsLink2;
    this.request.requestorMaterial[0].itemsLink3 =
      this.requestForm.value.itemsLink3;
    this.request.requestorMaterial[0].itemsLink4 =
      this.requestForm.value.itemsLink4;
    this.request.requestorMaterial[0].soundtrackFileName =
      this.requestForm.value.soundtrackFileName;
    this.request.requestorMaterial[0].soundtrackLink =
      this.requestForm.value.soundtrackLink;
    // this.request.requestorMaterial[0].hasThemeSong = this.requestForm.value.soundtrackLink || this.requestForm.value.soundtrackFileName || null;

    this.request.requestorMaterial[0].finalAnimatedLogo =
      this.requestForm.value.finalAnimatedLogo;
    this.request.collectionLink = this.requestForm.value.collectionLink;
    this.request.requestorMaterial[0].hasThemeSong =
      this.requestForm.value.hasThemeSong;
    this.request.requestorMaterial[0].themeSongFileName =
      this.requestForm.value.themeSongFileName;
    this.request.collectionFlag =
      this.requestForm.value.collectionFlag || false;
    this.request.mediaUrl = this.requestForm.value.mediaUrl;
    this.request.projectResponsible = this.requestForm.value.projectResponsible;

    if (statusId != 0) {
      this.request.statusId = statusId;
    }

    this.request.requestHistoryLog.push({
      id: 0,
      comments: this.requestForm.value.requestHistoryLogComment,
      description:
        statusId != 0
          ? 'Request set as ' + this.statusDescription[statusId]
          : 'Request set as Updated',
      requestId: this.request.id,
      updated: new Date(),
      updatedBy: this.userService.User.enterpriseId,
    });

    if (this.requestForm.value.requestAdminLogComment.length > 0) {
      this.request.requestAdminLog.push({
        id: 0,
        comments: this.requestForm.value.requestAdminLogComment,
        description:
          statusId != 0
            ? 'Request set as ' + this.statusDescription[statusId]
            : 'Request set as Updated',
        requestId: this.request.id,
        updated: new Date(),
        updatedBy: this.userService.User.enterpriseId,
      });
    }

    // mapping end
    this.requestIn.set('Request', JSON.stringify(this.request));
    // requestIn.append("Request[OfficeId]", this.request.officeId.toString())
    this.uploading = true;
    this.dataBaseService.updateRequest(this.requestIn).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(
            (event.loaded * 100) / event.total
          ).toString();
          this.isDisabled = true;
          if (this.progress != '100') {
            this._snackBar.open(
              'Uploading Request ' + this.progress + '%',
              '',
              {}
            );
          } else {
            this._snackBar.open('Waiting for server...', '', {});
          }
        }
        if (event.type === HttpEventType.Response) {
          this.progress = 'Upload completed';
          this.isDisabled = false;
          console.log(event);
          this._snackBar.open(this.progress, 'OK', {
            duration: 10000,
          });
        }
      },
      (err) => {
        console.log(err);
        this._snackBar.open('Error ' + err.status, err.error.title, {
          duration: 5000,
        });
      }
    );
  }

  delete() {
    const dialogRef = this.dialog.open(DeletePopupComponent, {
      width: '550px',
      data: { id: this.request.id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result) {
        this.router.navigate(['../Home']);
      }
    });
  }
}
