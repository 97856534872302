import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UserDtoOut } from '../Models/Models';
import * as Model from '../../core/Models/Models';

@Injectable({
  providedIn: 'root',
})
export class DataBaseService {
  constructor(private http: HttpClient) {}

  LogIn(): Observable<UserDtoOut> {
    return this.http.get<any>('touchcastapi/user/LogIn');
  }

  GetClaims(): Observable<UserDtoOut> {
    return this.http.get<any>(
      'touchcastapi/user/GetClaims'
    );
  }

  getButtons(): Observable<string[]> {
    return this.http.get<string[]>('touchcastapi/homemenu/getNumbers');
  }

  getUserByEnterpriseId(account: string): Observable<UserDtoOut> {
    return this.http.get<UserDtoOut>(
      'touchcastapi/user/getUserByEnterpriseId' + `/${account}`
    );
  }

  getUsers(): Observable<any> {
    return this.http.get<any>('touchcastapi/user/get');
  }

  addUser(body): Observable<any> {
    return this.http.post<any>('touchcastapi/user/add', body, {
      observe: 'response',
    });
  }

  deleteUser(id): Observable<any> {
    return this.http.delete<any>('touchcastapi/user/delete/' + id, {
      observe: 'response',
    });
  }

  editUser(id, body): Observable<any> {
    return this.http.put<any>('touchcastapi/user/edit/' + id, body, {
      observe: 'response',
    });
  }

  getEventLogs(): Observable<any> {
    return this.http.get<any>('touchcastapi/eventlogentry/get');
  }

  getErrorLogs(): Observable<any> {
    return this.http.get<any>('touchcastapi/errorlogentry/get');
  }

  getSettings(): Observable<any> {
    return this.http.get<any>('touchcastapi/setting/get');
  }

  editSetting(body): Observable<any> {
    return this.http.put<any>('touchcastapi/setting/edit', body, {
      observe: 'response',
    });
  }

  getOffices(): Observable<any> {
    return this.http.get<any>('touchcastapi/office/get');
  }
  addOffice(body): Observable<any> {
    return this.http.post<any>('touchcastapi/office/add', body, { observe: 'response' });
  }
  deleteOffice(id): Observable<any> {
    return this.http.delete<any>('touchcastapi/office/delete/' + id, {
      observe: 'response',
    });
  }
  editOffice(body): Observable<any> {
    return this.http.put<any>('touchcastapi/office/edit', body, { observe: 'response' });
  }

  getProjectResponsibles(): Observable<any> {
    return this.http.get<any>('touchcastapi/ProjectResponsible/get');
  }
  addProjectResponsible(body): Observable<any> {
    return this.http.post<any>('touchcastapi/ProjectResponsible/add', body, {
      observe: 'response',
    });
  }
  deleteProjectResponsible(id): Observable<any> {
    return this.http.delete<any>('touchcastapi/ProjectResponsible/delete/' + id, {
      observe: 'response',
    });
  }

  getLocations(): Observable<any> {
    return this.http.get<any>('touchcastapi/location/get');
  }
  addLocation(body): Observable<any> {
    return this.http.post<any>('touchcastapi/location/add', body, {
      observe: 'response',
    });
  }
  deleteLocation(id): Observable<any> {
    return this.http.delete<any>('touchcastapi/location/delete/' + id, {
      observe: 'response',
    });
  }
  editLocation(body): Observable<any> {
    return this.http.put<any>('touchcastapi/location/edit', body, {
      observe: 'response',
    });
  }

  getRequestTypes(): Observable<any> {
    return this.http.get<any>('touchcastapi/requestType/get');
  }
  addRequestType(body): Observable<any> {
    return this.http.post<any>('touchcastapi/requestType/add', body, {
      observe: 'response',
    });
  }
  deleteRequestType(id): Observable<any> {
    return this.http.delete<any>('touchcastapi/requestType/delete/' + id, {
      observe: 'response',
    });
  }
  editRequestType(body): Observable<any> {
    return this.http.put<any>('touchcastapi/requestType/edit', body, {
      observe: 'response',
    });
  }

  getRequestSubTypes(): Observable<any> {
    return this.http.get<any>('touchcastapi/requestSubType/get');
  }
  addRequestSubType(body): Observable<any> {
    return this.http.post<any>('touchcastapi/requestSubType/add', body, {
      observe: 'response',
    });
  }
  deleteRequestSubType(id): Observable<any> {
    return this.http.delete<any>('touchcastapi/requestSubType/delete/' + id, {
      observe: 'response',
    });
  }
  editRequestSubType(body): Observable<any> {
    return this.http.put<any>('touchcastapi/requestSubType/edit', body, {
      observe: 'response',
    });
  }

  getMailTemplateTable(): Observable<any> {
    return this.http.get<any>('touchcastapi/MailTemplate/getTable');
  }
  getMailTemplate(id): Observable<any> {
    return this.http.get<any>('touchcastapi/MailTemplate/getTemplate/' + id, {
      observe: 'response',
    });
  }
  editMailTemplate(body): Observable<any> {
    return this.http.put<any>('touchcastapi/MailTemplate/edit', body, {
      observe: 'response',
    });
  }

  getMailPriority(): Observable<any> {
    return this.http.get<any>('touchcastapi/MailPriority/get', { observe: 'response' });
  }

  getFilteredPeople(
    account: string,
    includeGroups?: boolean
  ): Observable<string[]> {
    return this.http.get<any>('touchcastapi/people/Filter' + `/${account}`);
  }
  isValidAccount(account: string): Observable<any> {
    if (account.length == 0) {
      return of(false);
    }
    return this.http.get<any>('touchcastapi/people/IsValidAccount' + `/${account}`);
  }

  getRequests(allRequests: boolean): Observable<any> {
    return this.http.get<any>('touchcastapi/Request/get?allRequests=' + allRequests, {
      observe: 'response',
    });
  }

  getHomeData(): Observable<any> {
    return this.http.get<any>('touchcastapi/Request/GetHomeData', {
      observe: 'response',
    });
  }

  addRequest(body): Observable<any> {
    return this.http.post<any>('touchcastapi/Request/Add', body, {
      observe: 'events',
      reportProgress: true,
    });
  }

  updateRequest(body): Observable<any> {
    return this.http.post<any>('touchcastapi/Request/Update', body, {
      observe: 'events',
      reportProgress: true,
    });
  }

  getRequestById(id): Observable<HttpResponse<Model.RequestDtoIn>> {
    return this.http.get<Model.RequestDtoIn>('touchcastapi/Request/GetById/' + id, {
      observe: 'response',
    });
  }

  deleteRequest(id): Observable<any> {
    return this.http.delete<any>('touchcastapi/request/delete/' + id, {
      observe: 'response',
    });
  }

  deleteRequestById(id): Observable<any> {
    return this.http.delete<any>('touchcastapi/request/deleteById/' + id, {
      observe: 'response',
    });
  }

  isValidWbs(wbs: string): Observable<any> {
    return this.http.get<any>('touchcastapi/people/isValidWbs/' + wbs, {
      observe: 'response',
    });
  }

  isValidLevel(sponsor: string): Observable<any> {
    return this.http.get<any>('touchcastapi/people/hasValidLevel' + `/${sponsor}`);
  }

  getProfile(eid: string): Observable<any> {
    return this.http.get<any>('touchcastapi/people/getProfile' + `/${eid}`);
  }

  public getData(route: string) {
    return this.http.get<any>(route);
  }

  public downloadFile(id: number, fileType: string, actorIndex: number) {
    return this.http.get(
      'touchcastapi/request/getFile?id=' +
        id +
        '&fileType=' +
        fileType +
        '&actorIndex=' +
        actorIndex,
      { responseType: 'blob', observe: 'response' }
    );
  }

  public getPdf(id: number) {
    return this.http.get('touchcastapi/request/getpdf/' + id, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
