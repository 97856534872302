import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'rebar-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.css'],
})
export class BetaComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    setTimeout(() => {
      this.openDialog();
    }, 4000);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalWarning, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      this.openDialog();
    });
  }
}

@Component({
  selector: 'modal_warning',
  templateUrl: 'modal_warning.html',
})
export class ModalWarning {
  constructor(public dialogRef: MatDialogRef<ModalWarning>) {}

  onNoClick(): void {}
}
