import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataBaseService } from '../../core/services/data-base.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl } from '@angular/forms';

import * as Model from '../../core/Models/Models';

@Component({
  selector: 'rebar-about-pop-up',
  templateUrl: './about-pop-up.component.html',
  styleUrls: ['./about-pop-up.component.css'],
})
export class AboutPopUpComponent {
  constructor(public dialogRef: MatDialogRef<AboutPopUpComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
