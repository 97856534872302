import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppConfigService } from './core/services/app-config.service';
import { APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalRedirectComponent, MsalModule } from '@azure/msal-angular';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';
import { DatadogSessionService } from './core/services/datadog-session.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTooltipModule } from '@angular/material/tooltip';
import { routing } from './app.routing';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { RequestViewComponent } from './feature/request-view/request-view.component';
import { AboutPopUpComponent } from './feature/about-pop-up/about-pop-up.component';
import { HomeComponent } from './feature/home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { FormEditViewComponent } from './feature/form-edit-view/form-edit-view.component';
import { DeletePopupComponent } from './shared/delete-popup/delete-popup.component';
import { CollectionFlagPopupComponent } from './shared/collection-flag-popup/collection-flag-popup.component';
import { BetaComponent, ModalWarning } from './shared/beta/beta.component';
import { FormComponent } from './feature/form/form.component';
import { AddApiDomainInterceptorService } from './core/Helpers/add-api-domain-interceptor.service';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    HomeComponent,
    AboutPopUpComponent,
    RequestViewComponent,
    UnauthorizedComponent,
    FormComponent,
    BetaComponent,
    ModalWarning,
    FormEditViewComponent,
    CollectionFlagPopupComponent,
    DeletePopupComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    routing,
    RebarAuthModule.forRoot(),
    MsalModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatInputModule,
    MatCardModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => () => config.load(),
      deps: [AppConfigService],
      multi: true,
    },
    DatadogSessionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddApiDomainInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
