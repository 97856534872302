<div class="mainDiv">

    <mat-grid-list cols="4" rowHeight="60px">
        <mat-grid-tile>
            <h2>Create Request</h2>
        </mat-grid-tile>
        <mat-grid-tile>
            Status:
            <span class="badge" style="background-color: #327031; border: #327031;color: snow;">
                <mat-icon class="smallIcon">add_box</mat-icon> New
            </span>
        </mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>
            <div style="display: flex; flex-direction: column;">
                <div>Requestor: {{request.requestor}}</div>
                <div>Date: {{request.created | date}}</div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>

    <mat-divider></mat-divider>

    <div [formGroup]="requestForm">
        <mat-card>
            <mat-card-header>
                <mat-icon class="infoIcon"
                    matTooltip="This information is relevant to define the sponsor of the project who will approve it and to properly define the team who is in charge.">
                    info</mat-icon>
                <h5>General Information</h5>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div>
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Project name" formControlName="title">
                            <mat-error *ngIf="requestForm.controls.title.hasError('required')">
                                Project name is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field>
                            <input type="text" placeholder="Sponsor" formControlName="approver" aria-label="Number"
                                matInput [matAutocomplete]="approver">
                            <mat-autocomplete #approver="matAutocomplete">
                                <mat-option *ngIf="approverisLoading">
                                    <mat-progress-spinner [mode]="'indeterminate'" diameter="20">
                                    </mat-progress-spinner>
                                </mat-option>
                                <mat-option *ngFor="let option of approveroptions" [value]="option.account">
                                    {{option.account}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="requestForm.controls.approver.hasError('validatePerson')">
                                Please select a valid account
                            </mat-error>
                        </mat-form-field>

                        <mat-icon class="infoIcon" matTooltip="Manager or above.">info</mat-icon>
                        <mat-error *ngIf="requestForm.controls.approver.hasError('validateLevel')">
                            The Sponsor must be Manager, Senior Manager or Accenture Leadership
                        </mat-error>

                    </div>
                    <div>
                        <mat-form-field>
                            <input type="text" placeholder="Reference Team" formControlName="requestTeam"
                                aria-label="Number" matInput [matAutocomplete]="requestTeam">
                            <mat-autocomplete #requestTeam="matAutocomplete">
                                <mat-option *ngIf="requestTeamisLoading">
                                    <mat-progress-spinner [mode]="'indeterminate'" diameter="20">
                                    </mat-progress-spinner>
                                </mat-option>
                                <mat-option *ngFor="let option of requestTeamoptions" [value]="option.account">
                                    {{option.account}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="!requestForm.controls.requestTeam.valid">
                                Please select a valid account
                            </mat-error>
                        </mat-form-field>


                        <button (click)="addToReferenceTeam()" mat-raised-button class="addBtn"
                            [disabled]="!requestForm.controls.requestTeam.valid || requestForm.controls.requestTeam.value.length == 0">
                            <mat-icon class="BtnIcon">+</mat-icon> Add
                        </button>
                        <button *ngFor="let item of request.requestTeam; let i = index" (click)="DeleteRequestTeam(i)"
                            mat-button color='primary' class="addBtn" [disabled]="isDisabled">
                            {{ item.enterpriseId }}
                        </button>
                        <mat-icon class="infoIcon" matTooltip="If possible, add two names.">info</mat-icon>
                    </div>
                </div>


            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-icon class="infoIcon"
                    matTooltip="Provide the video type you need referring to the final audience and specify your required Delivery date which will be confirmed by TouchCast Team.">
                    info</mat-icon>
                <h5>Specifications</h5>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div>
                    <mat-form-field>
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="requestTypeId" (selectionChange)="requestTypeChange()">
                            <mat-option *ngFor="let requestType of requestTypes" [value]="requestType.id">
                                {{ requestType.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Audience</mat-label>
                        <mat-select formControlName="requestSubTypeId">
                            <mat-option *ngFor="let requestSubType of filteredRequestSubTypes"
                                [value]="requestSubType.id">
                                {{ requestSubType.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-icon class="infoIcon"
                        matTooltip="Select video audience: a.Internal: Accenture personnel only b.	External: social channels, television, clients, etc.">
                        info</mat-icon>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Required Delivery Date</mat-label>
                        <input formControlName="expectedDate" matInput [matDatepicker]="picker" (click)="italyLocale()" class="date">
                        <mat-datepicker-toggle matSuffix [for]="picker" (click)="italyLocale()"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                        <mat-error *ngIf="!requestForm.controls.expectedDate.valid">
                            {{requestForm.controls.expectedDate.errors.validateExpectedDate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-icon style="margin: 0px 10px 0px 2px;" class="infoIcon"
                        matTooltip="Type your required delivery date considering that we need at least 10 days to complete the project.">
                        info</mat-icon>

                    <mat-form-field *ngIf="userService.isAdministrator || userService.isTCTeam">
                        <mat-label>Confirmed Delivery Date</mat-label>
                        <input formControlName="effectiveDate" matInput [matDatepicker]="picker1" (click)="italyLocale()" class="date">
                        <mat-datepicker-toggle matSuffix [for]="picker1" (click)="italyLocale()"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker1></mat-datepicker>
                        <mat-error *ngIf="!requestForm.controls.effectiveDate.valid">
                            {{requestForm.controls.effectiveDate.errors.validateEffectiveDate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Note" formControlName="typeDetails">
                        <mat-error *ngIf="requestForm.controls.typeDetails.hasError('maxlength')">
                            Note is too long
                        </mat-error>
                    </mat-form-field>
                    <mat-icon class="infoIcon" matTooltip="Add your comments here">info</mat-icon>
                </div>

            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-icon class="infoIcon"
                    matTooltip="This service is for free. We need a WBS element to only charge travel expenses, overtime hours, urgencies managing and external on-site recording.">
                    info</mat-icon>
                <h5>Financial</h5>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="WBS" formControlName="wbs">
                        <mat-error *ngIf="requestForm.controls.wbs.hasError('maxlength')">
                            WBS is too long
                        </mat-error>
                        <mat-error *ngIf="requestForm.controls.wbs.hasError('validateWbs')">
                            Invalid Wbs
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-icon class="infoIcon"
                    matTooltip="Provide the names of people involved in this video. Please, specify if they are Accenture personnel (employee/external) or Third party (Client i.e.).">
                    info</mat-icon>
                <h5>Actors</h5>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div>
                    <mat-form-field style="margin-top: 5px;">
                        <mat-label>Actor/Actress</mat-label>
                        <mat-select formControlName="isExternal" (selectionChange)="clearActors()">
                            <mat-option value=0>Accenture Personnel</mat-option>
                            <mat-option value=1>Third Party</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="requestForm.controls.isExternal.value == 1">
                        <input matInput placeholder="Actor" formControlName="externalActor">
                        <mat-error *ngIf="requestForm.controls.externalActor.hasError('maxlength')">
                            Actor is too long
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="requestForm.controls.isExternal.value == 0">
                        <input type="text" placeholder="Actor" formControlName="internalActor" aria-label="Number"
                            matInput [matAutocomplete]="internalActor">
                        <mat-autocomplete #internalActor="matAutocomplete">
                            <mat-option *ngIf="internalActorisLoading">
                                <mat-progress-spinner [mode]="'indeterminate'" diameter="20">
                                </mat-progress-spinner>
                            </mat-option>
                            <mat-option *ngFor="let option of internalActoroptions" [value]="option.account">
                                {{option.account}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="!requestForm.controls.internalActor.valid">
                            Please select a valid account
                        </mat-error>
                    </mat-form-field>

                    <button (click)="addActor()" mat-raised-button class="addBtn"
                        [disabled]="!requestForm.controls.externalActor.valid || !requestForm.controls.internalActor.valid
                    || requestForm.controls.externalActor.value.length == 0 && requestForm.controls.internalActor.value.length == 0">
                        <mat-icon class="BtnIcon">+</mat-icon> Add
                    </button>

                    <div class="actorGrid" *ngIf="request.requestorActor.length > 0">
                        <span>Name</span>
                        <span>Type</span>
                        <span>Subtitles</span>
                        <span>Speech</span>
                        <span> <span class="link" (click)="getPdf(1)">Disclaimer</span>
                            <mat-icon class="infoIcon"
                                matTooltip="Click on Disclaimer to download an approved template">info</mat-icon>
                        </span>
                        <ng-container *ngFor="let item of request.requestorActor; let i = index">
                            <button mat-button color="primary" (click)="DeleteActor(i)" [disabled]="isDisabled">
                                {{ item.actor }}
                            </button>
                            <mat-form-field>
                                <input *ngIf="item.isExternal" matInput value='Third Party' readonly='true'>
                                <input *ngIf="!item.isExternal" matInput value='Accenture Personnel' readonly='true'>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Subtitle" [formControl]="subtitlesForm[i]">
                                <mat-error *ngIf="subtitlesForm.controls[i].hasError('maxlength')">
                                    Subtitles are too long.
                                </mat-error>
                            </mat-form-field>



                            <div>
                                <mat-form-field>
                                    <input matInput readonly='true' placeholder="Speech File"
                                        [formControl]="speechFileNamesForm[i]">
                                </mat-form-field>
                                <input id="file1" name="file" type="file" class="inputFile"
                                    (change)="addSpeechFile($event, i)" #SFi>

                                <mat-button-toggle-group name="fontStyle">
                                    <mat-button-toggle class="toggle-btn" (click)="SFi.click()">
                                        <mat-icon class="toggleIcon">folder</mat-icon>
                                    </mat-button-toggle>
                                    <button mat-button color="primary" class="file-btn" (click)="clearSpeechFile(i)">
                                        <mat-icon class="toggleIcon">clear</mat-icon>
                                    </button>
                                </mat-button-toggle-group>
                            </div>


                            <div *ngIf="item.isExternal">
                                <mat-form-field>
                                    <input matInput readonly='true' placeholder="Disclaimer File"
                                        [formControl]="disclaimerFileNamesForm[i]">
                                </mat-form-field>
                                <input id="file2" name="file" type="file" class="inputFile"
                                    (change)="addDisclaimerFile($event, i)" #DFi>

                                <mat-button-toggle-group name="fontStyle">
                                    <mat-button-toggle class="toggle-btn" (click)="DFi.click()">
                                        <mat-icon class="toggleIcon">folder</mat-icon>
                                    </mat-button-toggle>
                                    <button mat-button color="primary" class="file-btn"
                                        (click)="clearDisclaimerFile(i)">
                                        <mat-icon class="toggleIcon">clear</mat-icon>
                                    </button>
                                </mat-button-toggle-group>
                            </div>


                            <span *ngIf="!item.isExternal"></span>


                        </ng-container>
                    </div>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Where</mat-label>
                        <mat-select formControlName="office" (selectionChange)="officeChange()">
                            <mat-option *ngFor="let office of offices" [value]="office">
                                {{ office.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-icon class="infoIcon"
                        matTooltip="Select&nbsp;the&nbsp;location&nbsp;set&nbsp;for&nbsp;the recording specifying the room, if in office, or the address for “Other” option">
                        info</mat-icon>



                    <mat-form-field>
                        <mat-label>Location</mat-label>
                        <mat-select *ngIf="!requestForm.value.office.isExternal" formControlName="location">
                            <mat-option *ngFor="let location of filteredLocations" [value]="location.description">
                                {{ location.description }}
                            </mat-option>
                        </mat-select>
                        <input *ngIf="requestForm.value.office.isExternal" matInput placeholder="External Location"
                            formControlName="location">
                        <mat-error *ngIf="requestForm.controls.location.hasError('maxlength')">
                            Location is too long
                        </mat-error>

                    </mat-form-field>
                </div>

            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-icon class="infoIcon"
                    matTooltip="Provide all relevant stuff you need to set up your video: backgrounds, interactive items and video soundtrack.">
                    info</mat-icon>
                <h5>Materials</h5>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <mat-grid-list cols="3" rowHeight="40px">
                    <mat-grid-tile colspan="3">
                        <h6>
                            <mat-icon class="iconMaterial"
                                matTooltip="Click on Uploading Procedures for detailed instructions">info</mat-icon>
                            <span class="link" (click)="getPdf(3)"> Uploading Procedures:</span>
                            <mat-icon class="iconMaterial">folder</mat-icon>Local File |
                            <mat-icon class="iconMaterial">photo</mat-icon>Brandspace |
                            <mat-icon class="iconMaterial">camera_alt</mat-icon>Istock
                        </h6>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="3">
                        <mat-icon class="infoIcon"
                            matTooltip="Add up to 3 different files to be used with Green Screen feature. Allowed formats are: .jpg, .bmp e .mp4">
                            info</mat-icon>
                        Background
                    </mat-grid-tile>
                    <mat-grid-tile rowspan="1.5">
                        <ng-container [ngSwitch]="BF1Selected">
                            <ng-container *ngSwitchCase="'file'">
                                <mat-form-field>
                                    <input matInput readonly='true' formControlName="backgroundFileName1"
                                        placeholder="Local File">
                                    <mat-error *ngIf="!requestForm.controls.backgroundFileName1.valid">
                                        {{requestForm.controls.backgroundFileName1.errors.validateFileExtension}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'link'">
                                <mat-form-field>
                                    <input matInput formControlName="backgroundLink1" placeholder="Link">
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mat-form-field>
                                    <input matInput readonly='true' placeholder="">
                                </mat-form-field>
                            </ng-container>
                        </ng-container>
                        <input id="file3" name="file" type="file" class="inputFile" accept=".png,.bmp,.jpg,.mp4,.zip"
                            (change)=BF1file($event) #BF1>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="BF1.click()">
                                <mat-icon class="toggleIcon">folder</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="BF1link('brandspace')">
                                <mat-icon class="toggleIcon">photo_alternate</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="BF1link('istock')">
                                <mat-icon class="toggleIcon">camera_alt</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="BF1clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <ng-container [ngSwitch]="BF2Selected">
                            <ng-container *ngSwitchCase="'file'">
                                <mat-form-field>
                                    <input matInput readonly='true' formControlName="backgroundFileName2"
                                        placeholder="Local File">
                                </mat-form-field>
                                <mat-error *ngIf="!requestForm.controls.backgroundFileName2.valid">
                                    {{requestForm.controls.backgroundFileName2.errors.validateFileExtension}}
                                </mat-error>
                            </ng-container>
                            <ng-container *ngSwitchCase="'link'">
                                <mat-form-field>
                                    <input matInput formControlName="backgroundLink2" placeholder="Link">
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mat-form-field>
                                    <input matInput readonly='true' placeholder="">
                                </mat-form-field>
                            </ng-container>
                        </ng-container>

                        <input id="file4" name="file" type="file" class="inputFile" accept=".png,.bmp,.jpg,.mp4,.zip"
                            (change)=BF2file($event) #BF2>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="BF2.click()">
                                <mat-icon class="toggleIcon">folder</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="BF2link('brandspace')">
                                <mat-icon class="toggleIcon">photo_alternate</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="BF2link('istock')">
                                <mat-icon class="toggleIcon">camera_alt</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="BF2clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <ng-container [ngSwitch]="BF3Selected">
                            <ng-container *ngSwitchCase="'file'">
                                <mat-form-field>
                                    <input matInput readonly='true' formControlName="backgroundFileName3"
                                        placeholder="Local File">
                                    <mat-error *ngIf="!requestForm.controls.backgroundFileName3.valid">
                                        {{requestForm.controls.backgroundFileName3.errors.validateFileExtension}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'link'">
                                <mat-form-field>
                                    <input matInput formControlName="backgroundLink3" placeholder="Link">
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mat-form-field>
                                    <input matInput readonly='true' placeholder="">
                                </mat-form-field>
                            </ng-container>
                        </ng-container>

                        <input id="file5" name="file" type="file" class="inputFile" accept=".png,.bmp,.jpg,.mp4,.zip"
                            (change)=BF3file($event) #BF3>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="BF3.click()">
                                <mat-icon class="toggleIcon">folder</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="BF3link('brandspace')">
                                <mat-icon class="toggleIcon">photo_alternate</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="BF3link('istock')">
                                <mat-icon class="toggleIcon">camera_alt</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="BF3clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="3">
                        <mat-icon class="infoIcon"
                            matTooltip="Add images, videos, documents (.pdf) and specific links to create interactivity.">
                            info</mat-icon>
                        Interactive Items (vApp)
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <mat-form-field>
                            <input matInput readonly='true' formControlName="itemsFileName1" placeholder="Local File">
                            <mat-error *ngIf="!requestForm.controls.itemsFileName1.valid">
                                {{requestForm.controls.itemsFileName1.errors.validateFileExtension}}
                            </mat-error>
                        </mat-form-field>

                        <input id="file6" name="file" type="file" class="inputFile" (change)=IF1file($event)
                            accept=".pdf,.png,.bmp,.jpg,.mp4,.zip" #IF1>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="IF1.click()">
                                <mat-icon class="toggleIcon">folder</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="IF1clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <mat-form-field>
                            <input matInput readonly='true' formControlName="itemsFileName2" placeholder="Local File">
                            <mat-error *ngIf="!requestForm.controls.itemsFileName2.valid">
                                {{requestForm.controls.itemsFileName2.errors.validateFileExtension}}
                            </mat-error>
                        </mat-form-field>

                        <input id="file7" name="file" type="file" class="inputFile" (change)=IF2file($event)
                            accept=".pdf,.png,.bmp,.jpg,.mp4,.zip" #IF2>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="IF2.click()">
                                <mat-icon class="toggleIcon">folder</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="IF2clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <mat-form-field>
                            <input #IL1 matInput formControlName="itemsLink1" placeholder="Link">
                        </mat-form-field>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="IL1link('brandspace')">
                                <mat-icon class="toggleIcon">photo_alternate</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="IL1link('istock')">
                                <mat-icon class="toggleIcon">camera_alt</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="IL1clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <mat-form-field>
                            <input #IL2 matInput formControlName="itemsLink2" placeholder="Link">
                        </mat-form-field>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="IL2link('brandspace')">
                                <mat-icon class="toggleIcon">photo_alternate</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="IL2link('istock')">
                                <mat-icon class="toggleIcon">camera_alt</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="IL2clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <mat-form-field>
                            <input #IL3 matInput formControlName="itemsLink3" placeholder="Link">
                        </mat-form-field>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="IL3link('brandspace')">
                                <mat-icon class="toggleIcon">photo_alternate</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="IL3link('istock')">
                                <mat-icon class="toggleIcon">camera_alt</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="IL3clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <mat-form-field>
                            <input #IL4 matInput formControlName="itemsLink4" placeholder="Link">
                        </mat-form-field>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="IL4link('brandspace')">
                                <mat-icon class="toggleIcon">photo_alternate</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="IL4link('istock')">
                                <mat-icon class="toggleIcon">camera_alt</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="IL4clear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="3" *ngIf="requestForm.hasError('validateItems')">
                        <mat-error >
                            {{requestForm.errors.validateItems}}
                        </mat-error>
                    </mat-grid-tile>

                    <mat-grid-tile colspan="3">
                        <mat-icon class="infoIcon" matTooltip="Add an audio file (.mp3)">info</mat-icon>
                        Soundtrack
                    </mat-grid-tile>

                    <mat-grid-tile rowspan="1.5">
                        <ng-container [ngSwitch]="SFSelected">
                            <ng-container *ngSwitchCase="'file'">
                                <mat-form-field>
                                    <input matInput readonly='true' formControlName="soundtrackFileName"
                                        placeholder="Local File">
                                    <mat-error *ngIf="!requestForm.controls.soundtrackFileName.valid">
                                        {{requestForm.controls.soundtrackFileName.errors.validateFileExtension}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'link'">
                                <mat-form-field>
                                    <input matInput formControlName="soundtrackLink" placeholder="Link">
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mat-form-field>
                                    <input matInput readonly='true' disabled='true' placeholder="Choose type">
                                </mat-form-field>
                            </ng-container>
                        </ng-container>

                        <input id="file8" name="file" type="file" class="inputFile" accept=".mp3"
                            (change)=SFfile($event) #SF>

                        <mat-button-toggle-group name="fontStyle">
                            <mat-button-toggle class="toggle-btn" (click)="SF.click()">
                                <mat-icon class="toggleIcon">folder</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="SFlink('brandspace')">
                                <mat-icon class="toggleIcon">photo_alternate</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle-btn" (click)="SFlink('istock')">
                                <mat-icon class="toggleIcon">camera_alt</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn toggle-btn" (click)="SFclear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </mat-grid-tile>

                </mat-grid-list>

                <div *ngIf="requestForm.value.effectiveDate != null">
                    <hr>
                    <mat-card-subtitle>
                        <mat-icon class="infoIcon" matTooltip="Click on Optional Pack for detailed information.">info
                        </mat-icon>
                        Optional Pack
                    </mat-card-subtitle>
                    <mat-grid-list cols="2" rowHeight="55px">
                        <mat-grid-tile class="formGrid">
                            <mat-form-field class="longDiv">
                                <mat-label>Initial Theme</mat-label>
                                <mat-select formControlName="hasThemeSong" (selectionChange)="TSFclear()">
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="formGrid">
                            <mat-button-toggle-group name="fontStyle" style="margin: 0px 3px 8px 0px;">
                                <mat-button-toggle class="toggle-btn" (click)="openUrl('logo')">
                                    <mat-icon class="toggleIcon">link</mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                            <mat-form-field class="longDiv">
                                <input matInput formControlName="finalAnimatedLogo" placeholder="Animated Logo">
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                    <div *ngIf="requestForm.value.hasThemeSong">
                        <mat-form-field class="longDiv">
                            <input matInput readonly='true' formControlName="themeSongFileName"
                                placeholder="Local File">
                        </mat-form-field>

                        <input id="file" name="file" type="file" class="inputFile" (change)=TSFfile($event)
                            accept=".mp4,.ppt,.pptx" #TSF>

                        <mat-button-toggle-group name="fontStyle" style="margin: 0px 3px 8px 0px;">
                            <mat-button-toggle class="toggle-btn" (click)="TSF.click()">
                                <mat-icon class="toggleIcon">folder</mat-icon>
                            </mat-button-toggle>
                            <button mat-button color="primary" class="file-btn" (click)="TSFclear()">
                                <mat-icon class="toggleIcon">clear</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                    </div>

                    <mat-grid-list cols="2" rowHeight="55px">
                        <mat-grid-tile class="formGrid">
                            <mat-form-field class="longDiv">
                                <mat-label>Publishing in CIO TouchCast Collection</mat-label>
                                <mat-select formControlName="collectionFlag" (selectionChange)="collectionFlagChange()">
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                                <mat-hint>Public or Private Link.</mat-hint>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="formGrid" *ngIf="userService.isAdministrator || userService.isTCTeam">
                            <button mat-button color="primary" disabled='true'>
                                <mat-icon class="toggleIcon">link</mat-icon>
                            </button>
                            <mat-form-field class="longDiv">
                                <input matInput formControlName="collectionLink" placeholder="Collection Link">
                                <mat-hint>Media Deployment.</mat-hint>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
                <hr>
                <mat-grid-list cols="2" rowHeight="55px" *ngIf="userService.isAdministrator || userService.isTCTeam">
                    <mat-grid-tile class="formGrid">
                        <mat-form-field class="longDiv">
                            <mat-label>Project Responsible</mat-label>
                            <mat-select formControlName="projectResponsible">
                                <mat-option *ngFor="let projectResponsible of projectResponsibles"
                                    [value]="projectResponsible.enterpriseId">
                                    {{ projectResponsible.enterpriseId }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="formGrid">
                        <mat-button-toggle-group name="fontStyle" style="margin: 0px 3px 8px 0px;">
                            <button mat-button color="primary" class="file-btn" disabled='true'>
                                <mat-icon class="toggleIcon">link</mat-icon>
                            </button>
                        </mat-button-toggle-group>
                        <mat-form-field class="longDiv">
                            <input matInput formControlName="mediaUrl" placeholder="Media Link">
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>

            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-content>
                <table class="full-width">
                    <tr class="full-width">
                        <td class="full-width">
                            <mat-form-field class="full-width">
                                <mat-label>Comments</mat-label>
                                <textarea matInput formControlName="requestHistoryLogComment" cdkTextareaAutosize
                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>
                            </mat-form-field>
                        </td>
                        <td class="half-width" *ngIf="userService.isAdministrator || userService.isTCTeam">
                            <mat-form-field class="full-width">
                                <mat-label>Private Comments</mat-label>
                                <textarea matInput formControlName="requestAdminLogComment" cdkTextareaAutosize
                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>

            </mat-card-content>
            <mat-card-actions>
                <button mat-button color="primary" (click)="create(2)"
                    [disabled]="requestForm.invalid || !requestForm.valid || isDisabled || request.requestor == null" style="border: #337ab7;">
                    <mat-icon>check</mat-icon> Submit
                </button>
                <button mat-button color="primary" (click)="create(1)"
                    [disabled]="requestForm.invalid || !requestForm.valid || isDisabled" style="border: #1F4776">
                    <mat-icon>save</mat-icon> Save as Draft
                </button>
                <button mat-button color="primary" routerLink="../Home" [disabled]="isDisabled"
                    style="border: #800909;">
                    <mat-icon>clear</mat-icon> Cancel
                </button>

            </mat-card-actions>
        </mat-card>

    </div>

</div>
<!-- <mat-card>
    <button (click)="DeleteById()">DeleteById</button>
    <input type="text" [(ngModel)]="id">
</mat-card> -->
